const ME = [
  {
    id: 0,
    url: "info",
    linkName: "Info",
    title: "Hello",
    description: "Namaste",

    imgUrl:
      "https://images.unsplash.com/photo-1668934807819-536c2ca08a7c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    imgPath: "/assets/works/gallery/mock-1.svg",
    component: "Info",
  },
  {
    id: 1,
    url: "works",
    linkName: "works",
    title: "Works",
    description: "baking artsy delicacies, pushing the tool, playing, lab",
    imgUrl:
      "https://images.unsplash.com/photo-1668934807819-536c2ca08a7c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    imgPath: "/assets/works/gallery/mock-1.svg",
    component: null,
  },

  {
    id: 2,
    url: "magic",
    linkName: "Magic",
    title: "Magic",
    description: " baking artsy delicacies, pushing the tool, playing, lab",
    imgUrl:
      "https://images.unsplash.com/photo-1668934807819-536c2ca08a7c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    imgPath: "/assets/works/gallery/mock-1.svg",
    component: null,
  },

  {
    id: 3,
    url: "tools",
    linkName: "tools",
    title: "Tools & Technologies",
    description: "Isn't creativity an immortal tool to have?",

    imgUrl:
      "https://images.unsplash.com/photo-1668934807819-536c2ca08a7c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    imgPath: "/assets/works/gallery/mock-1.svg",
    component: "Toolkit",
  },
  {
    id: 4,
    url: "links",
    linkName: "Links",
    title: "Links",
    description: "Let's connect, collaborate, talk and vibe on design & life.",

    imgUrl:
      "https://images.unsplash.com/photo-1668934807819-536c2ca08a7c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    imgPath: "/assets/works/gallery/mock-1.svg",
    component: "Links",
  },
];

export default ME;
