import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Link, Switch, useNavigate } from "react-router-dom";
import { Route } from "react-router";

//components
import ThemeToggle from "../../ThemeToggle";
//utils
// import TRANSITIONS from "../utils/transitions";
// import VARIANTS from "../utils/variants";

//local styles
import styles from "./Info.module.css";

function Info(props) {
  const navigate = useNavigate();
  return (
    <div className={`${styles.container} drilldown-page `}>
      {/* <div className={``}>
        <img alt="image" src={process.env.PUBLIC_URL + "/assets/info.webp"} />
      </div> */}

      <div className={``}>
        Hello. I'm a designer based in India, building digital products driven
        by delightful humane experiences, usable interfaces and systems that
        scales them—for both humans & businesses.
      </div>

      <div className={``}>
        With over 3 years of industry experience, I've designed AI-enabled B2B
        SaaS products at HighRadius. It's been a fulfilling journey, working
        across domains like Record-to-Report, Accounts Receivables, financial
        operations & more. From mid-market to growth-stage products, I
        collaborated closely with top-tier management to kickstart design
        vision, drive product direction, and lead a team to design solutions for
        use-cases of varying complexities. Ensuring regular quality assurance
        checks during development, I've delivered impactful products.
      </div>

      <div className={``}>
        Additionally, I've freelanced for clients across various industries like
        manufacturing, packaging, real estate, food, ed tech & more. Worth
        mentioning that it has been wonderful to design digital graphics and
        creative put-ups for the communities and NGOs where I volunteered.
      </div>
      <div className={` `}>
        I adore good experiences, yet I can't resist critiquing bad ones and
        grabbing the chance to improve them. Ultimately, we design for humans,
        and experience encompasses people's feelings, consciously engaged or
        not. My goal is to enhance those positive feelings.
      </div>

      <div className={` `}>
        In my free time, I enjoy good music, baking delicious Figma cookies,
        coding themes of my website, building community resources, drawing
        inspiration from everyday experiences, binge-watching, and exploring new
        cuisines. I have an affinity for anything with a creative touch. While
        I'm aware that we don't live in an ideal world, I pursue perfection in
        everything I create, fueled by my eye for detail.
      </div>

      <div className={`font-body-small opac-uhi`}>
        Thank you for taking the time to read this through.
      </div>
    </div>
  );
}

export default Info;
