import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  Routes,
  Outlet,
  useParams,
  useNavigate,
  Navigate,
  Route,
} from "react-router-dom";

//components

//utils

import TRANSITIONS from "../utils/transitions";
import VARIANTS from "../utils/variants";
//local styles
import styles from "./Footer.module.css";

function Footer() {
  const variants = VARIANTS;
  const transitions = TRANSITIONS;
  var [date, setDate] = useState(new Date());
  const navigate = useNavigate();

  const location = useLocation();

  const [SJHeight, getSJheight] = useState(null);
  const [size, setSize] = useState({ container: 0, text: 0 });

  const SJRef = useRef(null);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useLayoutEffect(() => {
    //re-render without refresh on window size change...
    const SJEl = SJRef.current;
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    getSJheight(SJEl.clientHeight);
    // console.log(SJHeight);
    return () => window.removeEventListener("resize", updateSize);
  }, [window.innerWidth]);

  // date - time
  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  }, [date]);

  return (
    <div className={`${styles.container}`}>
      <div
        className={`${styles.footerText} font-caption opac-hi flex flex-jc-sb  gap padding`}
      >
        {window.innerWidth <= 600 ? (
          <div className={` fb-60 flex flex-col  flex-ai-fs`}>
            <div className={``}>© 2023</div>
            <div className={``}>Designed &amp; Coded w/ Love, IN</div>
          </div>
        ) : (
          <>
            <div className={``}>© 2023</div>
            <div className={`fb-40`}>Designed &amp; coded with Love, India</div>
          </>
        )}

        {window.innerWidth <= 600 ? (
          <div className={` fb-40 flex flex-col flex-ai-fe`}>
            {/* IST 10:00AM  */}
            <div className={`ta-r`}>IST&nbsp;{date.toLocaleTimeString()}</div>
            {/* (Monday) 2 Jul, 2023  */}
            <div className={` ta-r`}>
              ({days[date.getDay()]})&nbsp;{date.getDate()}&nbsp;
              {months[date.getMonth()]},&nbsp;{date.getFullYear()}
            </div>
          </div>
        ) : (
          <>
            {/* IST 10:00AM  */}
            <div className={`fb-15`}>IST&nbsp;{date.toLocaleTimeString()}</div>
            {/* (Monday) 2 Jul, 2023  */}
            <div className={`fb-15 ta-r`}>
              ({days[date.getDay()]})&nbsp;{date.getDate()}&nbsp;
              {months[date.getMonth()]},&nbsp;{date.getFullYear()}
            </div>
          </>
        )}
      </div>
      <div className={`${styles.samarthJajodia} padding `} ref={SJRef}>
        {window.innerWidth <= 600 ? (
          <svg
            className={`${styles.svgSJ}`}
            width="1880"
            height="640"
            viewBox="0 0 1880 640"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M131.769 126.018L129.704 125.505C111.514 120.986 95.7932 117.08 84.3978 110.662C78.5792 107.385 74.1041 103.559 71.0672 98.8486C68.0502 94.169 66.2907 88.3661 66.2907 80.8509C66.2907 69.305 70.5178 60.8769 78.6764 55.1683C87.0625 49.3004 100.061 45.9874 118.124 45.9874C138.876 45.9874 153.531 50.6533 163.553 59.0402C173.55 67.4065 179.498 79.945 181.871 96.865C182.137 98.7642 183.77 100.178 185.697 100.178H232.385C233.46 100.178 234.487 99.7313 235.219 98.9462C235.95 98.1611 236.319 97.1082 236.237 96.041C233.912 65.7657 221.495 41.6159 200.812 25.0898C180.193 8.6145 151.719 0 117.715 0C85.648 0 59.1205 7.34712 40.517 21.5761C21.7837 35.9045 11.467 56.9304 11.467 83.2956C11.467 112.47 24.8691 131.117 44.1115 143.844C63.0545 156.374 87.7489 163.23 110.572 169.312L110.621 169.325C113.162 169.967 115.64 170.589 118.055 171.195C141.23 177.01 158.605 181.37 170.614 188.008C177.103 191.595 181.763 195.723 184.842 200.903C187.923 206.086 189.615 212.647 189.615 221.419C189.615 233.985 186.233 244.475 177.853 251.91C169.381 259.428 155.128 264.432 132.048 264.432C105.202 264.432 86.681 257.956 74.5058 247.263C62.3551 236.591 55.9824 221.24 54.4008 202.358C54.234 200.365 52.56 198.833 50.5506 198.833H3.86355C2.8056 198.833 1.79393 199.265 1.06464 200.027C0.335356 200.79 -0.0477384 201.816 0.00476621 202.868C1.69561 236.722 16.0133 263.75 39.6107 282.245C63.132 300.681 95.578 310.419 133.277 310.419C170.56 310.419 198.41 300.884 216.984 284.44C235.609 267.951 244.439 244.929 244.439 218.975C244.439 203.021 240.981 189.963 234.72 179.162C228.467 168.372 219.547 160.059 208.958 153.388C188.054 140.22 160.11 133.169 132.163 126.118L131.769 126.018ZM128.468 129.16C129.249 129.354 130.033 129.549 130.82 129.744L128.468 129.16Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M239.117 299.531C238.676 300.711 238.843 302.031 239.565 303.065C240.288 304.098 241.473 304.715 242.738 304.715H290.244C291.895 304.715 293.363 303.672 293.899 302.118L317.166 234.634H434.099L457.366 302.118C457.902 303.672 459.37 304.715 461.021 304.715H510.165C511.432 304.715 512.618 304.097 513.34 303.061C514.062 302.025 514.227 300.702 513.783 299.522L404.027 8.19894C403.462 6.6984 402.02 5.70423 400.409 5.70423H351.675C350.06 5.70423 348.616 6.70262 348.054 8.20778L239.117 299.531ZM418.787 189.054H333.282L375.851 61.9986L418.787 189.054Z"
              fill="current"
            />
            <path
              d="M532.576 300.871C532.576 302.994 534.305 304.715 536.439 304.715H580.669C582.803 304.715 584.533 302.994 584.533 300.871V94.7636L658.526 302.157C659.073 303.69 660.531 304.715 662.167 304.715H699.434C701.07 304.715 702.528 303.69 703.075 302.157L777.069 94.7635V300.871C777.069 302.994 778.798 304.715 780.932 304.715H825.162C827.296 304.715 829.026 302.994 829.026 300.871V9.54805C829.026 7.42516 827.296 5.70423 825.162 5.70423H765.779C764.161 5.70423 762.713 6.70824 762.154 8.21951L680.604 228.496L599.45 8.22517C598.892 6.71096 597.443 5.70423 595.822 5.70423H536.439C534.305 5.70423 532.576 7.42516 532.576 9.54805V300.871Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M848.03 299.531C847.588 300.711 847.756 302.031 848.478 303.065C849.2 304.098 850.385 304.715 851.651 304.715H899.157C900.807 304.715 902.276 303.672 902.811 302.118L926.079 234.634H1043.01L1066.28 302.118C1066.81 303.672 1068.28 304.715 1069.93 304.715H1119.08C1120.34 304.715 1121.53 304.097 1122.25 303.061C1122.97 302.025 1123.14 300.702 1122.7 299.522L1012.94 8.19894C1012.37 6.6984 1010.93 5.70423 1009.32 5.70423H960.587C958.973 5.70423 957.529 6.70262 956.966 8.20778L848.03 299.531ZM1027.7 189.054H942.194L984.764 61.9986L1027.7 189.054Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1141.49 300.871C1141.49 302.994 1143.22 304.715 1145.35 304.715H1190.4C1192.53 304.715 1194.26 302.994 1194.26 300.871V190.63H1265.76C1282.61 190.63 1293.13 196.546 1300 205.264C1307.04 214.2 1310.57 226.5 1312.35 239.755C1313.79 250.476 1314.06 261.414 1314.3 271.259L1314.3 271.292C1314.35 273.596 1314.41 275.84 1314.47 278.008C1314.65 283.642 1314.92 288.843 1315.6 293.064C1315.93 295.181 1316.39 297.177 1317.05 298.923C1317.7 300.648 1318.63 302.359 1320.04 303.675C1320.75 304.343 1321.7 304.715 1322.68 304.715H1371.01C1373.14 304.715 1374.87 302.994 1374.87 300.871V298.019C1374.87 296.344 1373.78 294.863 1372.18 294.356C1372.12 294.317 1371.96 294.18 1371.72 293.803C1371.31 293.155 1370.85 292.082 1370.41 290.449C1369.53 287.18 1368.95 282.546 1368.46 276.726C1368.18 273.323 1367.94 269.546 1367.68 265.508C1367.06 256.033 1366.35 245.116 1364.85 234.192C1362.7 218.556 1358.87 202.26 1351.01 188.981C1344.85 178.563 1336.2 170.004 1324.11 165.106C1352.14 154.182 1370.77 127.889 1370.77 93.4817C1370.77 65.723 1361.76 43.5658 1343.93 28.4229C1326.19 13.3624 1300.29 5.70423 1267.39 5.70423H1145.35C1143.22 5.70423 1141.49 7.42516 1141.49 9.54805V300.871ZM1194.26 144.235V52.0991H1258.38C1280.54 52.0991 1295.24 56.3289 1304.37 63.7156C1313.33 70.9621 1317.59 81.8036 1317.59 96.7412C1317.59 112.521 1312.32 124.154 1302.6 131.946C1292.74 139.848 1277.76 144.235 1257.56 144.235H1194.26Z"
              fill="current"
            />
            <path
              d="M1467.02 300.871C1467.02 302.994 1468.75 304.715 1470.88 304.715H1516.34C1518.48 304.715 1520.21 302.994 1520.21 300.871V52.0991H1610.95C1613.08 52.0991 1614.81 50.3782 1614.81 48.2553V9.54805C1614.81 7.42516 1613.08 5.70423 1610.95 5.70423H1376.28C1374.15 5.70423 1372.42 7.42516 1372.42 9.54805V48.2553C1372.42 50.3782 1374.15 52.0991 1376.28 52.0991H1467.02V300.871Z"
              fill="current"
            />
            <path
              d="M1638.02 300.871C1638.02 302.994 1639.75 304.715 1641.88 304.715H1686.93C1689.06 304.715 1690.79 302.994 1690.79 300.871V173.925H1826.81V300.871C1826.81 302.994 1828.54 304.715 1830.68 304.715H1876.14C1878.27 304.715 1880 302.994 1880 300.871V9.54805C1880 7.42516 1878.27 5.70423 1876.14 5.70423H1830.68C1828.54 5.70423 1826.81 7.42516 1826.81 9.54805V127.53H1690.79V9.54805C1690.79 7.42516 1689.06 5.70423 1686.93 5.70423H1641.88C1639.75 5.70423 1638.02 7.42516 1638.02 9.54805V300.871Z"
              fill="current"
            />
            <path
              d="M306.941 339.129C306.941 337.006 305.211 335.285 303.077 335.285H258.028C255.895 335.285 254.165 337.006 254.165 339.129V539.592C254.165 556.256 252.483 569.738 246.706 579.027C241.203 587.873 231.505 593.605 213.389 593.605C195.054 593.605 185.49 587.867 180.145 579.062C174.509 569.777 173.023 556.303 173.023 539.592V527.776C173.023 525.653 171.293 523.932 169.159 523.932H125.748C123.615 523.932 121.885 525.653 121.885 527.776V539.592C121.885 564.635 126.756 589.736 140.926 608.658C155.25 627.786 178.62 640 214.208 640C249.795 640 273.261 627.788 287.687 608.669C301.962 589.75 306.941 564.648 306.941 539.592V339.129Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M318.266 629.112C317.825 630.292 317.993 631.612 318.715 632.645C319.437 633.679 320.622 634.296 321.887 634.296H369.394C371.044 634.296 372.512 633.252 373.048 631.699L396.315 564.215H513.248L536.515 631.699C537.051 633.252 538.519 634.296 540.17 634.296H589.314C590.581 634.296 591.768 633.678 592.49 632.642C593.212 631.606 593.377 630.283 592.932 629.103L483.176 337.78C482.611 336.279 481.169 335.285 479.559 335.285H430.824C429.21 335.285 427.765 336.283 427.203 337.789L318.266 629.112ZM497.937 518.635H412.431L455 391.58L497.937 518.635Z"
              fill="current"
            />
            <path
              d="M771.476 339.129C771.476 337.006 769.747 335.285 767.613 335.285H722.564C720.43 335.285 718.7 337.006 718.7 339.129V539.592C718.7 556.256 717.019 569.738 711.241 579.027C705.738 587.873 696.041 593.605 677.924 593.605C659.589 593.605 650.026 587.867 644.68 579.062C639.044 569.777 637.558 556.303 637.558 539.592V527.776C637.558 525.653 635.828 523.932 633.694 523.932H590.284C588.15 523.932 586.42 525.653 586.42 527.776V539.592C586.42 564.635 591.291 589.736 605.461 608.658C619.785 627.786 643.155 640 678.743 640C714.33 640 737.796 627.788 752.222 608.669C766.498 589.75 771.476 564.648 771.476 539.592V339.129Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1129.05 630.452C1129.05 632.575 1130.78 634.296 1132.92 634.296H1245.13C1286.53 634.296 1320.02 621.057 1343.12 595.431C1366.16 569.864 1378.4 532.469 1378.4 484.994C1378.4 437.504 1365.95 400.009 1342.7 374.346C1319.41 348.629 1285.72 335.285 1244.31 335.285H1132.92C1130.78 335.285 1129.05 337.006 1129.05 339.129V630.452ZM1181.83 587.901V381.68H1242.26C1275.11 381.68 1295.21 393.889 1307.31 412.424C1319.61 431.257 1323.99 457.15 1323.99 484.994C1323.99 512.839 1319.61 538.625 1307.32 557.353C1295.22 575.784 1275.13 587.901 1242.26 587.901H1181.83Z"
              fill="current"
            />
            <path
              d="M1411.31 630.452C1411.31 632.575 1413.04 634.296 1415.17 634.296H1460.22C1462.36 634.296 1464.09 632.575 1464.09 630.452V339.129C1464.09 337.006 1462.36 335.285 1460.22 335.285H1415.17C1413.04 335.285 1411.31 337.006 1411.31 339.129V630.452Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1483.2 629.112C1482.76 630.292 1482.93 631.612 1483.65 632.645C1484.37 633.679 1485.56 634.296 1486.82 634.296H1534.33C1535.98 634.296 1537.45 633.252 1537.99 631.699L1561.25 564.215H1678.19L1701.45 631.699C1701.99 633.252 1703.46 634.296 1705.11 634.296H1754.25C1755.52 634.296 1756.71 633.678 1757.43 632.642C1758.15 631.606 1758.31 630.283 1757.87 629.103L1648.11 337.78C1647.55 336.279 1646.11 335.285 1644.5 335.285H1595.76C1594.15 335.285 1592.7 336.283 1592.14 337.789L1483.2 629.112ZM1662.87 518.635H1577.37L1619.94 391.58L1662.87 518.635Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M949.274 640C994.091 640 1031.09 623.617 1056.85 595.828C1082.59 568.071 1096.88 529.2 1096.88 484.587C1096.88 439.973 1082.59 401.201 1056.85 373.544C1031.08 345.856 994.083 329.581 949.274 329.581C904.465 329.581 867.47 345.856 841.7 373.544C815.959 401.201 801.663 439.973 801.663 484.587C801.663 529.2 815.958 568.071 841.695 595.828C867.462 623.617 904.457 640 949.274 640ZM856.487 484.587C856.487 455.146 863.241 427.945 878.088 408.202C892.81 388.624 915.783 375.976 949.274 375.976C982.765 375.976 1005.74 388.624 1020.46 408.202C1035.31 427.945 1042.06 455.146 1042.06 484.587C1042.06 514.027 1035.31 541.335 1020.45 561.183C1005.73 580.864 982.754 593.605 949.274 593.605C915.794 593.605 892.821 580.864 878.093 561.183C863.241 541.335 856.487 514.027 856.487 484.587Z"
              fill="current"
            />
          </svg>
        ) : (
          <svg
            className={`${styles.svgSJ} `}
            width="1880"
            height="162"
            viewBox="0 0 1880 162"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M68.6015 65.7731L67.526 65.5051C58.0563 63.1464 49.8717 61.1078 43.939 57.758C40.9097 56.0475 38.5799 54.0509 36.9988 51.5923C35.4281 49.1499 34.5121 46.1212 34.5121 42.1987C34.5121 36.1725 36.7128 31.7736 40.9603 28.7941C45.3263 25.7315 52.0935 24.0023 61.4975 24.0023C72.3013 24.0023 79.9307 26.4376 85.1485 30.815C90.3534 35.1816 93.4498 41.7259 94.6852 50.557C94.8239 51.5483 95.6739 52.286 96.6774 52.286H120.983C121.544 52.286 122.078 52.053 122.459 51.6433C122.84 51.2335 123.032 50.6839 122.989 50.1269C121.779 34.3252 115.314 21.7207 104.546 13.0952C93.8115 4.49619 78.9878 0 61.2843 0C44.5899 0 30.7792 3.8347 21.0939 11.2613C11.341 18.7397 5.96993 29.7138 5.96993 43.4747C5.96993 58.702 12.9473 68.4344 22.9653 75.0771C32.8273 81.6164 45.6836 85.1952 57.5657 88.3696L57.5912 88.3763C58.9142 88.7112 60.2041 89.0357 61.4613 89.352C73.5266 92.3873 82.5725 94.663 88.8245 98.1273C92.2031 99.9994 94.6289 102.154 96.232 104.858C97.8359 107.563 98.717 110.988 98.717 115.566C98.717 122.124 96.956 127.599 92.5934 131.48C88.1829 135.404 80.7624 138.016 68.7467 138.016C54.7699 138.016 45.1276 134.636 38.7891 129.054C32.4632 123.484 29.1454 115.472 28.322 105.617C28.2352 104.577 27.3637 103.777 26.3176 103.777H2.01143C1.46064 103.777 0.93395 104.003 0.554271 104.401C0.174592 104.799 -0.0248534 105.335 0.00248137 105.883C0.882766 123.553 8.33683 137.66 20.622 147.313C32.8676 156.935 49.7596 162.018 69.3863 162.018C88.7964 162.018 103.296 157.041 112.966 148.458C122.662 139.853 127.259 127.837 127.259 114.29C127.259 105.963 125.459 99.148 122.2 93.5106C118.944 87.879 114.3 83.5398 108.787 80.0582C97.9045 73.1856 83.3559 69.5054 68.8066 65.825L68.6015 65.7731ZM66.8829 67.4128C67.2893 67.514 67.6974 67.6156 68.107 67.7178L66.8829 67.4128Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M124.489 156.335C124.259 156.951 124.346 157.64 124.722 158.179C125.098 158.719 125.715 159.041 126.374 159.041H151.106C151.966 159.041 152.73 158.496 153.009 157.685L165.122 122.463H226L238.113 157.685C238.392 158.496 239.156 159.041 240.016 159.041H265.601C266.261 159.041 266.878 158.718 267.254 158.177C267.63 157.637 267.716 156.946 267.484 156.33L210.344 4.2793C210.049 3.49611 209.299 2.97722 208.46 2.97722H183.088C182.248 2.97722 181.496 3.49832 181.203 4.28391L124.489 156.335ZM218.028 98.6737H173.512L195.675 32.3591L218.028 98.6737Z"
              fill="current"
            />
            <path
              d="M277.268 157.034C277.268 158.142 278.169 159.041 279.28 159.041H302.307C303.418 159.041 304.318 158.142 304.318 157.034V49.4602L342.84 157.706C343.125 158.506 343.884 159.041 344.736 159.041H364.138C364.989 159.041 365.749 158.506 366.034 157.706L404.556 49.4602V157.034C404.556 158.142 405.456 159.041 406.567 159.041H429.594C430.705 159.041 431.605 158.142 431.605 157.034V4.98344C431.605 3.87543 430.705 2.97722 429.594 2.97722H398.678C397.836 2.97722 397.082 3.50125 396.791 4.29003L354.334 119.259L312.084 4.29298C311.794 3.50267 311.039 2.97722 310.195 2.97722H279.28C278.169 2.97722 277.268 3.87543 277.268 4.98344V157.034Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M441.499 156.335C441.27 156.951 441.357 157.64 441.733 158.179C442.109 158.719 442.726 159.041 443.384 159.041H468.117C468.976 159.041 469.741 158.496 470.02 157.685L482.133 122.463H543.01L555.124 157.685C555.402 158.496 556.167 159.041 557.026 159.041H582.612C583.271 159.041 583.889 158.718 584.265 158.177C584.641 157.637 584.727 156.946 584.495 156.33L527.354 4.2793C527.06 3.49611 526.309 2.97722 525.471 2.97722H500.099C499.258 2.97722 498.507 3.49832 498.214 4.28391L441.499 156.335ZM535.039 98.6737H490.523L512.685 32.3591L535.039 98.6737Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M594.279 157.034C594.279 158.142 595.18 159.041 596.29 159.041H619.744C620.855 159.041 621.755 158.142 621.755 157.034V99.4962H658.975C667.748 99.4962 673.228 102.584 676.804 107.134C680.468 111.798 682.303 118.218 683.233 125.136C683.984 130.731 684.122 136.44 684.246 141.579L684.246 141.596C684.275 142.799 684.303 143.97 684.339 145.101C684.43 148.042 684.571 150.756 684.923 152.96C685.099 154.065 685.338 155.106 685.68 156.018C686.018 156.918 686.5 157.811 687.235 158.498C687.608 158.847 688.1 159.041 688.611 159.041H713.77C714.881 159.041 715.781 158.142 715.781 157.034V155.546C715.781 154.671 715.214 153.899 714.38 153.634C714.351 153.614 714.267 153.542 714.141 153.346C713.926 153.007 713.687 152.447 713.458 151.595C713 149.888 712.698 147.47 712.447 144.432C712.3 142.656 712.172 140.685 712.036 138.577C711.716 133.632 711.348 127.934 710.566 122.232C709.447 114.072 707.45 105.566 703.36 98.6356C700.152 93.198 695.651 88.7304 689.355 86.174C703.947 80.4728 713.649 66.7495 713.649 48.7911C713.649 34.303 708.954 22.7384 699.671 14.8348C690.439 6.97429 676.953 2.97722 659.828 2.97722H596.29C595.18 2.97722 594.279 3.87543 594.279 4.98344V157.034ZM621.755 75.2812V27.1922H655.137C666.671 27.1922 674.326 29.3999 679.08 33.2553C683.744 37.0374 685.96 42.6959 685.96 50.4924C685.96 58.7282 683.215 64.8002 678.154 68.8669C673.022 72.9911 665.226 75.2812 654.71 75.2812H621.755Z"
              fill="current"
            />
            <path
              d="M763.757 157.034C763.757 158.142 764.658 159.041 765.769 159.041H789.435C790.546 159.041 791.447 158.142 791.447 157.034V27.1922H838.687C839.798 27.1922 840.698 26.294 840.698 25.186V4.98344C840.698 3.87543 839.798 2.97722 838.687 2.97722H716.517C715.406 2.97722 714.505 3.87543 714.505 4.98344V25.186C714.505 26.294 715.406 27.1922 716.517 27.1922H763.757V157.034Z"
              fill="current"
            />
            <path
              d="M852.781 157.034C852.781 158.142 853.682 159.041 854.793 159.041H878.246C879.357 159.041 880.258 158.142 880.258 157.034V90.7772H951.072V157.034C951.072 158.142 951.973 159.041 953.083 159.041H976.75C977.861 159.041 978.761 158.142 978.761 157.034V4.98344C978.761 3.87543 977.861 2.97722 976.75 2.97722H953.083C951.973 2.97722 951.072 3.87543 951.072 4.98344V66.5622H880.258V4.98344C880.258 3.87543 879.357 2.97722 878.246 2.97722H854.793C853.682 2.97722 852.781 3.87543 852.781 4.98344V157.034Z"
              fill="current"
            />
            <path
              d="M1124.49 4.98344C1124.49 3.87543 1123.59 2.97722 1122.48 2.97722H1099.03C1097.92 2.97722 1097.02 3.87543 1097.02 4.98344V109.612C1097.02 118.309 1096.14 125.346 1093.13 130.194C1090.27 134.811 1085.22 137.803 1075.79 137.803C1066.24 137.803 1061.26 134.808 1058.48 130.212C1055.55 125.366 1054.77 118.334 1054.77 109.612V103.444C1054.77 102.336 1053.87 101.438 1052.76 101.438H1030.16C1029.05 101.438 1028.15 102.336 1028.15 103.444V109.612C1028.15 122.682 1030.69 135.784 1038.06 145.66C1045.52 155.643 1057.69 162.018 1076.21 162.018C1094.74 162.018 1106.96 155.644 1114.47 145.665C1121.9 135.791 1124.49 122.689 1124.49 109.612V4.98344Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1130.39 156.335C1130.16 156.951 1130.25 157.64 1130.62 158.179C1131 158.719 1131.62 159.041 1132.27 159.041H1157.01C1157.87 159.041 1158.63 158.496 1158.91 157.685L1171.02 122.463H1231.9L1244.01 157.685C1244.29 158.496 1245.06 159.041 1245.92 159.041H1271.5C1272.16 159.041 1272.78 158.718 1273.15 158.177C1273.53 157.637 1273.62 156.946 1273.39 156.33L1216.24 4.27929C1215.95 3.49611 1215.2 2.97722 1214.36 2.97722H1188.99C1188.15 2.97722 1187.4 3.49832 1187.1 4.28391L1130.39 156.335ZM1223.93 98.6737H1179.41L1201.58 32.3592L1223.93 98.6737Z"
              fill="current"
            />
            <path
              d="M1366.34 4.98344C1366.34 3.87543 1365.44 2.97722 1364.33 2.97722H1340.87C1339.76 2.97722 1338.86 3.87543 1338.86 4.98344V109.612C1338.86 118.309 1337.99 125.346 1334.98 130.194C1332.11 134.811 1327.07 137.803 1317.63 137.803C1308.09 137.803 1303.11 134.808 1300.33 130.212C1297.39 125.366 1296.62 118.334 1296.62 109.612V103.444C1296.62 102.336 1295.72 101.438 1294.61 101.438H1272.01C1270.9 101.438 1269.99 102.336 1269.99 103.444V109.612C1269.99 122.682 1272.53 135.784 1279.91 145.66C1287.37 155.643 1299.53 162.018 1318.06 162.018C1336.59 162.018 1348.8 155.644 1356.31 145.665C1363.75 135.791 1366.34 122.689 1366.34 109.612V4.98344Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1552.5 157.034C1552.5 158.142 1553.4 159.041 1554.51 159.041H1612.93C1634.48 159.041 1651.92 152.131 1663.95 138.756C1675.94 125.412 1682.32 105.894 1682.32 81.1153C1682.32 56.3286 1675.83 36.7586 1663.73 23.3644C1651.6 9.94199 1634.06 2.97722 1612.5 2.97722H1554.51C1553.4 2.97722 1552.5 3.87543 1552.5 4.98344V157.034ZM1579.97 134.826V27.1922H1611.44C1628.54 27.1922 1639 33.5644 1645.3 43.2383C1651.7 53.0682 1653.99 66.5827 1653.99 81.1153C1653.99 95.6482 1651.7 109.107 1645.31 118.882C1639.01 128.501 1628.55 134.826 1611.44 134.826H1579.97Z"
              fill="current"
            />
            <path
              d="M1699.45 157.034C1699.45 158.142 1700.35 159.041 1701.46 159.041H1724.91C1726.02 159.041 1726.92 158.142 1726.92 157.034V4.98344C1726.92 3.87543 1726.02 2.97722 1724.91 2.97722H1701.46C1700.35 2.97722 1699.45 3.87543 1699.45 4.98344V157.034Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1736.88 156.335C1736.65 156.951 1736.73 157.64 1737.11 158.179C1737.49 158.719 1738.1 159.041 1738.76 159.041H1763.49C1764.35 159.041 1765.12 158.496 1765.4 157.685L1777.51 122.463H1838.39L1850.5 157.685C1850.78 158.496 1851.54 159.041 1852.4 159.041H1877.99C1878.65 159.041 1879.27 158.718 1879.64 158.177C1880.02 157.637 1880.1 156.946 1879.87 156.33L1822.73 4.27929C1822.44 3.49611 1821.69 2.97722 1820.85 2.97722H1795.48C1794.64 2.97722 1793.88 3.49832 1793.59 4.28391L1736.88 156.335ZM1830.42 98.6737H1785.9L1808.06 32.3592L1830.42 98.6737Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1458.9 162.018C1482.24 162.018 1501.5 153.467 1514.91 138.963C1528.31 124.476 1535.75 104.188 1535.75 80.9026C1535.75 57.6172 1528.31 37.3808 1514.91 22.9457C1501.49 8.49468 1482.23 0 1458.9 0C1435.57 0 1416.31 8.49468 1402.9 22.9457C1389.5 37.3808 1382.05 57.6172 1382.05 80.9026C1382.05 104.188 1389.5 124.476 1402.9 138.963C1416.31 153.467 1435.57 162.018 1458.9 162.018ZM1410.6 80.9026C1410.6 65.5367 1414.11 51.3392 1421.84 41.0347C1429.51 30.8165 1441.47 24.215 1458.9 24.215C1476.34 24.215 1488.3 30.8165 1495.96 41.0347C1503.69 51.3392 1507.21 65.5367 1507.21 80.9026C1507.21 96.2687 1503.69 110.522 1495.96 120.881C1488.29 131.153 1476.33 137.803 1458.9 137.803C1441.47 137.803 1429.51 131.153 1421.85 120.881C1414.11 110.522 1410.6 96.2687 1410.6 80.9026Z"
              fill="current"
            />
          </svg>
        )}
      </div>
    </div>
  );
}

export default Footer;
