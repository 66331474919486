import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
//local styles
import styles from "./Menu.module.css";
import ThemeToggle from "./ThemeToggle";
//utils
import VARIANTS from "../utils/variants";
import ME from "../utils/me";

function Menu({ menu, showMenu, version, showVersions }) {
  const navigate = useNavigate();
  const variants = VARIANTS;
  const meItems = ME;

  return (
    <motion.div
      className={`${styles.container}  ta-c pos-a `}
      variants={variants.rise}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className={`${styles.heading} font-body-serif`}>Menu</div>
      {/* section */}
      <div className={`${styles.navigate} font-caption-bold flex flex-col`}>
        {meItems.map((item) => (
          <React.Fragment key={item.id}>
            {item.component ? (
              <NavLink
                key={item.id}
                to={item.url}
                className="link"
                onClick={() => {
                  showMenu(0);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                {item.linkName}
              </NavLink>
            ) : (
              <a
                key={item.id}
                href={`#${item.url}`}
                className="link"
                onClick={() => {
                  showMenu(0);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                {item.linkName}
              </a>
            )}
          </React.Fragment>
        ))}
      </div>
      {/* section */}
      <div className={`${styles.social} font-caption-bold flex flex-col `}>
        <a href="mailto:hello@samarth.design" className="link">
          Email
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.read.cv/samarthjajodia"
          className="link"
        >
          Read.cv
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.bento.me/samarthjajodia"
          className="link"
        >
          My Bento
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.figma.com/@samarthjajodia"
          className="link"
        >
          Figma
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/samarthjajodia"
          className="link"
        >
          Linkedin
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.topmate.io/samarthjajodia"
          className="link"
        >
          Book 1:1 call
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/samarthjajodia"
          className="link"
        >
          Instagram
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.twitter.com/samarthjajodia"
          className="link"
        >
          Twitter
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.medium.com/@samarthjajodia"
          className="link"
        >
          Medium
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/@samarthjajodia"
          className="link"
        >
          Youtube
        </a>
      </div>
      {/* section */}
      <div
        className={`${styles.version} font-caption-bold link`}
        onClick={() => {
          showMenu(0);
          showVersions(1);
        }}
      >
        <div className={``}>Theme</div>
      </div>
      {/* section */}
      <div
        className={`${styles.closeMenu} font-caption-bold link`}
        onClick={() => {
          showMenu(0);
        }}
      >
        <div className={`link`}>Close</div>
      </div>
    </motion.div>
  );
}

export default Menu;
