import React from "react";
import styles from "./WorkC.module.css";
import LoaderHOC from "../../../hocs/LoaderHOC";

function WorkC() {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.sectionDisc} `}>
        <div>
          You are currently viewing the following section as an
          image/video/figma since development is in progress.
        </div>
        Thanks for your patience. (Best viewed in Macbook 14")
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={
            process.env.PUBLIC_URL + "/assets/works/p-ca-mob/casa-mob-1.webp"
          }
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={
            process.env.PUBLIC_URL + "/assets/works/p-ca-mob/casa-mob-2.webp"
          }
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={
            process.env.PUBLIC_URL + "/assets/works/p-ca-mob/casa-mob-3.webp"
          }
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={
            process.env.PUBLIC_URL + "/assets/works/p-ca-mob/casa-mob-4.webp"
          }
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={
            process.env.PUBLIC_URL + "/assets/works/p-ca-mob/casa-mob-5.webp"
          }
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={
            process.env.PUBLIC_URL + "/assets/works/p-ca-mob/casa-mob-6.webp"
          }
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={
            process.env.PUBLIC_URL + "/assets/works/p-ca-mob/casa-mob-7.webp"
          }
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={
            process.env.PUBLIC_URL + "/assets/works/p-ca-mob/casa-mob-8.webp"
          }
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={
            process.env.PUBLIC_URL + "/assets/works/p-ca-mob/casa-mob-9.webp"
          }
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={
            process.env.PUBLIC_URL + "/assets/works/p-ca-mob/casa-mob-10.webp"
          }
        />
      </div>
    </div>
  );
}
export default WorkC;
