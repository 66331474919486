const MAGIC = [
  {
    id: 0,
    filter: "figma",
    mediaPath:
      "https://storage.googleapis.com/creatorspace-public/users%2Fcle81vmtb04shoc0y3m64bf79%2Fp09y5YG7NIwwidrP-1683105293441474.mp4",
  },
  {
    id: 3,
    filter: "figma",
    mediaPath:
      "https://storage.googleapis.com/creatorspace-public/users%2Fcle81vmtb04shoc0y3m64bf79%2FzGk2W1NnRojF4q4x-1678424392925091.mp4",
  },
  {
    id: 2,
    filter: "figma",
    mediaPath:
      "https://storage.googleapis.com/creatorspace-public/users%2Fcle81vmtb04shoc0y3m64bf79%2FzOllR5W3pUmjBgzF-1688448809318618.mp4",
  },

  {
    id: 11,
    filter: "figma",
    mediaPath:
      "https://storage.googleapis.com/creatorspace-public/users%2Fcle81vmtb04shoc0y3m64bf79%2FKjMOwMdEDOYeXxM6-20230218-155407-981.mp4",
  },

  {
    id: 10,
    filter: "figma",
    mediaPath:
      "https://storage.googleapis.com/creatorspace-public/users%2Fcle81vmtb04shoc0y3m64bf79%2FJzlnDH2J5qTDHRDx-1687440954310688.mp4",
  },

  {
    id: 5,
    filter: "figma",
    mediaPath:
      "https://storage.googleapis.com/creatorspace-public/users%2Fcle81vmtb04shoc0y3m64bf79%2Fh6XGddRbCDELZ9Xi-20230217-131922-667.mp4",
  },
  {
    id: 9,
    filter: "figma",
    mediaPath:
      "https://storage.googleapis.com/creatorspace-public/users%2Fcle81vmtb04shoc0y3m64bf79%2F48cn66C7QTyvaz4x-20230206-220709-599.mp4",
  },

  {
    id: 8,
    filter: "b3d",
    mediaPath: "/assets/arts/art4.webp",
  },
  {
    id: 12,
    filter: "b3d",
    mediaPath: "/assets/arts/art3.webp",
  },
  {
    id: 13,
    filter: "b3d",
    mediaPath: "/assets/arts/art6.webp",
  },
  {
    id: 7,
    filter: "ps",
    mediaPath: "/assets/arts/art8.webp",
  },
  {
    id: 6,
    filter: "ps",
    mediaPath: "/assets/arts/art10.webp",
  },
  {
    id: 4,
    filter: "b3d",
    mediaPath: "/assets/arts/art9.webp",
  },
  {
    id: 1,
    filter: "b3d",
    mediaPath: "/assets/arts/art2.webp",
  },
];
export default MAGIC;
