import React from "react";
import styles from "./WorkB.module.css";
import LoaderHOC from "../../../hocs/LoaderHOC";
function WorkB() {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.sectionDisc} `}>
        <div>
          You are currently viewing the following section as an
          image/video/figma since development is in progress.
        </div>
        Thanks for your patience. (Best viewed in Macbook 14")
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-ca/casa-1.webp"}
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-ca/casa-2.webp"}
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-ca/casa-3.webp"}
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-ca/casa-4.webp"}
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-ca/casa-5.webp"}
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-ca/casa-6.webp"}
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-ca/casa-7.webp"}
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-ca/casa-8.webp"}
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-ca/casa-9.webp"}
        />
      </div>
      <div className={`${styles.temp}`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-ca/casa-10.webp"}
        />
      </div>
    </div>
  );
}

export default WorkB;
