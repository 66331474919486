import React from "react";
import styles from "./WorkH.module.css";
import LoaderHOC from "../../../hocs/LoaderHOC";

function WorkH() {
  return (
    <div className={`${styles.container} drilldown-page`}>
      <div className={`heading font-heading-2`}>Context</div>
      <div className={` `}>
        I participated in a 48-hour product design challenge where I worked on
        identifying the right problem that could bring impact followed by
        designing the solution — for a given mobile app called Purplle with the
        goal of benefiting both users and the business.
        <br /> <br />
        Purplle is a top-rated online beauty and cosmetics app in India, ranked
        #3 in the beauty category. It offers a mobile app with affordable
        options and a wide selection of popular brands like Mamaearth,
        Maybelline, L’Oreal, Sugar, and more.
      </div>

      <div className={`sub-heading font-body-heading`}>Timeline</div>

      <div className={`image img-lg`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-purplle/process.webp"}
        />
      </div>
      <div className={`heading font-heading-2`}>Problem Space</div>
      <div className={` `}>
        Beauty and personal care routines are an essential aspect of daily life
        and individuals often struggle to maintain consistently. Irregularities
        in beauty and personal care lowers effectiveness of the what a skin or
        hair care product is meant to deliver, leading to dissatisfaction and
        more. This issue not only affects personal health and wellness, but it
        also poses a challenge for businesses within the beauty industry.
      </div>
      <div className={`headng font-heading-1`}>
        Helping users improve consistency in maintaining their beauty and
        personal care routine.
      </div>
      <div className={`sub-heading font-body-heading`}>Thinking business</div>
      <div className={` `}>
        An important aspect of the design challenge was to address a problem
        that could have a positive impact on the business thereby improving key
        metrics
        <br /> <br />— increasing customer retention and boosting sales.
        <br /> <br />
        With these business goals to solve for, I thought it was crucial to
        address the problem of improving the consistency in beauty routine to
        support both user needs and business objectives. I found potential in
        this domain so I started to explore further on the problem.
      </div>
      <div className={`heading font-heading-2`}>Solution</div>
      <div className={` `}>
        Beauty and personal care routines are an essential aspect of daily life
        and individuals often struggle to maintain consistently. Irregularities
        in beauty and personal care lowers effectiveness of the what a skin or
        hair care product is meant to deliver, leading to dissatisfaction and
        more. This issue not only affects personal health and wellness, but it
        also poses a challenge for businesses within the beauty industry.
      </div>
      <div className={`image img-lg`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-purplle/sol-main.webp"}
        />
      </div>
      <div className={`image`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-purplle/sol-1.gif"}
        />
      </div>
      <div className={`image`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-purplle/sol-2.gif"}
        />
      </div>
      <div className={`image`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-purplle/sol-3.gif"}
        />
      </div>

      <div className={`heading font-heading-2`}>Process</div>
      <div className={`image img-lg`}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-purplle/read-cs.webp"}
        />
      </div>
      <div className={``}>
        <a
          href="https://uxplanet.org/introducing-subscribe-at-purplle-bad8c6b878f4"
          target="_blank"
          className="link font-caption-bold"
        >
          Read Case Study ↗
        </a>
      </div>
    </div>
  );
}
export default WorkH;
