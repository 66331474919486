const TRANSITIONS = {
  easeIn: {
    duration: 0.25,
    ease: "easeIn",
  },
  easeOut: {
    duration: 0.3,
    ease: "easeOut",
  },

  circOut: {
    duration: 1,
    ease: "circOut",
  },
  easeInOut: {
    duration: 0.5,
    ease: "easeInOut",
  },
};
export default TRANSITIONS;
