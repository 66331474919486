import React, { useEffect, useState, useRef } from "react";
import {
  motion,
  useMotionValue,
  useSpring,
  useAnimate,
} from "framer-motion/dist/framer-motion";
import useMouse from "@react-hook/mouse-position";

export default function Cursor({ menu, version }) {
  // cursor press check
  const cursor = useRef(null);
  const cursorPressed = useRef(false);
  // cursor move/animate
  const cursorX = useMotionValue(-10);
  const cursorY = useMotionValue(-10);
  const springConfig = { damping: 35, stiffness: 400 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);
  const [cursorText, setCursorText] = useState("");
  const [cursorShapeVariant, setCursorShapeVariant] = useState("default");
  const [cursorTextVariant, setCursorTextVariant] = useState("default");

  const shapeVariants = {
    default: {
      rotate: 0,
      transition: "ease",
    },
    link: {
      rotate: 135,
      transition: "ease",
    },
    externalLink: {
      rotate: 135,
      transition: "ease",
    },
  };
  const textVariants = {
    default: {
      opacity: 0,
      filter: "blur(calc(1 * var(--spacing)))",
      x: 10,
      y: 10,
      scale: 1.5,
      transition: "ease",
    },
    link: {
      opacity: 0,
      filter: "blur(calc(1 * var(--spacing)))",
      x: 10,
      y: 10,
      scale: 1.5,
      transition: "ease",
    },
    externalLink: {
      opacity: 1,
      filter: "blur(calc(0 * var(--spacing)))",
      x: 0,
      y: 0,
      scale: 1,
      transition: "ease",
    },
  };
  useEffect(() => {
    const moveCursor = (e) => {
      cursorX.set(e.clientX - 16);
      cursorY.set(e.clientY - 16);
    };

    const downCursor = () => {
      cursorPressed.current = true;
      toggleCursorSize();
    };

    const upCursor = () => {
      cursorPressed.current = false;
      toggleCursorSize();
    };

    const linkEnter = () => {
      setCursorText("");
      setCursorShapeVariant("link");
      setCursorTextVariant("link");
    };

    const linkLeave = () => {
      setCursorText("");
      setCursorShapeVariant("default");
      setCursorTextVariant("default");
    };

    const extLinkEnter = () => {
      setCursorText("External");
      setCursorShapeVariant("externalLink");
      setCursorTextVariant("externalLink");
    };

    const extLinkLeave = () => {
      setCursorText("");
      setCursorShapeVariant("default");
      setCursorTextVariant("default");
    };

    // cursor move/animate
    window.addEventListener("mousemove", moveCursor);
    // cursor press check
    window.addEventListener("mousedown", downCursor);
    window.addEventListener("mouseup", upCursor);

    const handleLinkEnter = (element) => {
      if (element.tagName.toLowerCase() === "a") {
        const href = element.getAttribute("href");
        if (href && href.startsWith("https")) {
          extLinkEnter();
        } else {
          linkEnter();
        }
      } else if (
        element.classList.contains("link") ||
        element.closest(".link")
      ) {
        linkEnter();
      }
    };

    const handleLinkLeave = (element) => {
      if (element.tagName.toLowerCase() === "a") {
        const href = element.getAttribute("href");
        if (href && href.startsWith("https")) {
          extLinkLeave();
        } else {
          linkLeave();
        }
      } else if (
        element.classList.contains("link") ||
        element.closest(".link")
      ) {
        linkLeave();
      }
    };

    // attaching collectively
    document.querySelectorAll(".link").forEach((element) => {
      element.addEventListener("mouseover", () => handleLinkEnter(element));
      element.addEventListener("mouseout", () => handleLinkLeave(element));
    });

    return () => {
      window.removeEventListener("mousemove", moveCursor);
      window.removeEventListener("mousedown", downCursor);
      window.removeEventListener("mouseup", upCursor);
      document.querySelectorAll(".link").forEach((element) => {
        element.removeEventListener("mouseover", () =>
          handleLinkEnter(element)
        );
        element.removeEventListener("mouseout", () => handleLinkLeave(element));
      });
    };
  }, []);
  useEffect(() => {
    const moveCursor = (e) => {
      cursorX.set(e.clientX - 16);
      cursorY.set(e.clientY - 16);
    };

    const downCursor = () => {
      cursorPressed.current = true;
      toggleCursorSize();
    };

    const upCursor = () => {
      cursorPressed.current = false;
      toggleCursorSize();
    };

    const linkEnter = () => {
      setCursorText("");
      setCursorShapeVariant("link");
      setCursorTextVariant("link");
    };

    const linkLeave = () => {
      setCursorText("");
      setCursorShapeVariant("default");
      setCursorTextVariant("default");
    };

    const extLinkEnter = () => {
      setCursorText("External");
      setCursorShapeVariant("externalLink");
      setCursorTextVariant("externalLink");
    };

    const extLinkLeave = () => {
      setCursorText("");
      setCursorShapeVariant("default");
      setCursorTextVariant("default");
    };

    // cursor move/animate
    window.addEventListener("mousemove", moveCursor);
    // cursor press check
    window.addEventListener("mousedown", downCursor);
    window.addEventListener("mouseup", upCursor);

    document.querySelectorAll(".link").forEach((link) => {
      const isAnchorTag = link.tagName.toLowerCase() === "a";
      const href = isAnchorTag ? link.getAttribute("href") : null;

      if (isAnchorTag && href && href.startsWith("https")) {
        link.addEventListener("mouseover", extLinkEnter);
        link.addEventListener("mouseout", extLinkLeave);
      } else {
        link.addEventListener("mouseover", linkEnter);
        link.addEventListener("mouseout", linkLeave);

        link.querySelectorAll("a[href^='https']").forEach((innerLink) => {
          innerLink.addEventListener("mouseover", extLinkEnter);
          innerLink.addEventListener("mouseout", extLinkLeave);
          link.addEventListener("mouseover", (e) => {
            e.stopPropagation();
          });
        });
      }
    });

    return () => {
      window.removeEventListener("mousemove", moveCursor);
      window.removeEventListener("mousedown", downCursor);
      window.removeEventListener("mouseup", upCursor);
      document.querySelectorAll(".link").forEach((link) => {
        link.removeEventListener("mouseover", linkEnter);
        link.removeEventListener("mouseout", linkLeave);

        link.querySelectorAll("a[href^='https']").forEach((innerLink) => {
          innerLink.removeEventListener("mouseover", extLinkEnter);
          innerLink.removeEventListener("mouseout", extLinkLeave);
        });
      });
    };
  }, [menu, version]);

  // cursor press check
  const toggleCursorSize = () => {
    if (cursorPressed.current) {
      cursor.current.style.height = "75%";
      cursor.current.style.width = "75%";
    } else {
      cursor.current.style.height = "100%";
      cursor.current.style.width = "100%";
    }
  };

  return (
    <div>
      <motion.div
        className="cursor"
        style={{
          translateX: cursorXSpring,
          translateY: cursorYSpring,
        }}
      >
        <div className={`cursor-shape pos-a`} ref={cursor}>
          <motion.div
            className={`cursor-lines`}
            variants={shapeVariants}
            animate={cursorShapeVariant}
          >
            <div className="cursor-line-horizontal"></div>
            <div className="cursor-line-vertical"></div>
          </motion.div>
        </div>
        <div className={`cursor-text font-caption pos-a b opac-uhi`}>
          <motion.div variants={textVariants} animate={cursorTextVariant}>
            {/* {cursorText} */}
            External
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}
