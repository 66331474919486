const THEMES = [
  {
    id: 1,
    title: "Theme 1",
    url: "t1.samarth.design",
    default: 1,
    imgUrl: "https://t1.samarth.design/meta-thumb.png",
    tags: "big typography, large scale, pure black, gradient loader, scroll events, animating cursor, bold, animated blur, uppercase typography",

    uiKit: 0,
  },
  {
    id: 0,
    title: "Theme 2",
    url: "t2.samarth.design",
    default: 0,
    imgUrl: "https://t2.samarth.design/meta-thumb.png",
    tags: "one page, big title, gradient background, text-heavy, uniform typography, no icons, left aligned",

    uiKit: 0,
  },
];
export default THEMES;
