import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import {
  NavLink,
  useLocation,
  Routes,
  Outlet,
  useParams,
  useNavigate,
  Navigate,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";

import {
  motion,
  useSpring,
  useTransform,
  AnimatePresence,
} from "framer-motion/dist/framer-motion";

//components
import Header from "./Header";
import Home from "./Home";
import Footer from "./Footer";
import Menu from "./Menu";
import Versions from "./Versions";
import LoaderHOC from "../hocs/LoaderHOC";
//import Work from "./pages/Work";
import WorkDetails from "./pages/WorkDetails";
import MeDetails from "./pages/MeDetails";

//utils

import VARIANTS from "../utils/variants";
import ARTS from "../utils/arts";
import WORKS from "../utils/works";
import ME from "../utils/me";

//local styles
import styles from "./Root.module.css";
import ThemeToggle from "./ThemeToggle";
import NoMatch from "./NoMatch";

//using locomotive scroll causing an issue on fixed overlay modals.

function Root({ gallery, menu, showMenu, version, showVersions }) {
  //const [modalOpen, setModalOpen] = useState(0);

  const location = useLocation();
  //const background = location.state && location.state.background;
  const workItems = WORKS;
  const meItems = ME;
  const artItems = ARTS;
  const variants = VARIANTS;
  //handle background click
  const handleBackgroundClick = (e) => {
    if (e.target.matches(".modal-background")) {
      showVersions(0);
      showMenu(0);
    }
  };
  return (
    <div>
      <motion.div
        className={`${styles.header} pos-f t l`}
        variants={variants.fall}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <Header
          showMenu={showMenu}
          menu={menu}
          version={version}
          showVersions={showVersions}
        />
      </motion.div>
      <AnimatePresence exitBeforeEnter>
        {window.innerWidth <= 900 && menu && (
          <motion.div
            className={`${styles.menu} pos-f t l w-100 h-100  modal-background `}
            key={menu}
            variants={variants.fade}
            initial="initial"
            animate="animate"
            exit="exit"
            onClick={handleBackgroundClick}
          >
            <Menu
              menu={menu}
              showMenu={showMenu}
              version={version}
              showVersions={showVersions}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter>
        {version && (
          <motion.div
            className={`${styles.version} pos-f t l  w-100 h-100 flex modal-background`}
            key={version}
            variants={variants.fade}
            initial="initial"
            animate="animate"
            exit="exit"
            onClick={handleBackgroundClick}
          >
            <Versions version={version} showVersions={showVersions} />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <motion.div
                className={`${styles.home}`}
                key={location}
                variants={variants.fade}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                <Home gallery={gallery} />
              </motion.div>
            }
          />

          {workItems.map(
            (item) =>
              item.component && (
                <Route
                  key={item.id + item.url}
                  path={`/${item.url}`}
                  element={
                    <motion.div
                      className={`${styles.workDetails}`}
                      variants={variants.fade}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                    >
                      <WorkDetails items={workItems} item={item} />
                    </motion.div>
                  }
                />
              )
          )}

          {meItems.map((item) => (
            <Route
              key={item.id + item.url}
              path={`/${item.url}`}
              element={
                <motion.div
                  className={`${styles.meDetails}`}
                  variants={variants.fade}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  <MeDetails items={meItems} item={item} />
                </motion.div>
              }
            />
          ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AnimatePresence>
      {/* footer  */}
      <div className={`${styles.footer}`}>
        <Footer />
      </div>
    </div>
  );
}

export default Root;
