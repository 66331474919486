import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Link, Switch, useNavigate } from "react-router-dom";
import { Route } from "react-router";

//components
import ThemeToggle from "../../ThemeToggle";
//utils
// import TRANSITIONS from "../utils/transitions";
// import VARIANTS from "../utils/variants";

//local styles
import styles from "./Archive.module.css";

function Archive(props) {
  const navigate = useNavigate();
  return (
    <div className={`${styles.container} drilldown-page`}>
      <div className={`ta-c font-caption opac-mid`}>coming soon</div>
    </div>
  );
}

export default Archive;
