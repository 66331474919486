import TRANSITIONS from "./transitions";

const VARIANTS = {
  fade: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,

      TRANSITIONS: { ...TRANSITIONS.easeOut, duration: 0.3 },
    },
    exit: {
      opacity: 0,
      TRANSITIONS: { ...TRANSITIONS.easeIn, duration: 0.3 },
    },
  },
  flyOut: {
    initial: {
      y: "105%",
    },
    animate: {
      y: "0%",
      TRANSITIONS: TRANSITIONS.easeOut,
    },
    exit: {
      y: "100%",
      TRANSITIONS: TRANSITIONS.easeIn,
    },
  },
  hMoveMirror: {
    initial: {
      x: "25%",
    },
    animate: {
      x: "0",
      TRANSITIONS: TRANSITIONS.easeOut,
    },
    exit: {
      x: "25%",
      TRANSITIONS: TRANSITIONS.easeIn,
    },
  },
  hMove: {
    initial: {
      x: "-25%",
    },
    animate: {
      x: "0",
      TRANSITIONS: TRANSITIONS.easeOut,
    },
    exit: {
      x: "-25%",
      TRANSITIONS: TRANSITIONS.easeIn,
    },
  },
  appear: {
    initial: {
      scale: 0.3,
      opacity: 0,
    },
    animate: {
      scale: 1,
      opacity: 1,
      TRANSITIONS: TRANSITIONS.circOut,
    },
    exit: {
      scale: 0.3,
      opacity: 0,
      TRANSITIONS: TRANSITIONS.circOut,
    },
  },
  ascend: {
    initial: {
      y: "5%",
      //scale: 0.8,
      opacity: 0,
    },
    animate: {
      y: "0%",
      //scale: 1,
      opacity: 1,
      TRANSITIONS: TRANSITIONS.easeOut,
    },
    exit: {
      y: "-5%",
      // scale: 0.8,
      opacity: 0,
      TRANSITIONS: TRANSITIONS.easeIn,
    },
  },
  ascend2: {
    initial: {
      y: "50%",
      //scale: 0.8,
    },
    animate: {
      y: "0%",
      //scale: 1,

      TRANSITIONS: TRANSITIONS.easeInOut,
    },
    exit: {
      y: "-50%",
      // scale: 0.8,

      TRANSITIONS: TRANSITIONS.easeInOut,
    },
  },
  descend: {
    initial: {
      y: "-100%",
    },
    animate: {
      y: "0%",

      TRANSITIONS: TRANSITIONS.easeInOut,
    },
    exit: {
      y: "-100%",

      TRANSITIONS: TRANSITIONS.easeInOut,
    },
  },

  test: {
    initial: {
      y: "200%",
    },
    animate: {
      y: "0%",

      TRANSITIONS: TRANSITIONS.easeOut,
    },
    exit: {
      y: "200%",

      TRANSITIONS: TRANSITIONS.easeIn,
    },
  },
  focus: {
    initial: {
      opacity: 0,
      filter: "blur(calc(5 * var(--spacing)))",
      transform: "scale(1)",
    },
    animate: {
      opacity: 1,
      filter: "blur(calc(0 * var(--spacing)))",
      transform: "scale(1)",
      transition: "ease",
      duration: 0.4,
    },
    exit: {
      opacity: 0,
      filter: "blur(calc(5 * var(--spacing)))",
      transform: "scale(0.95)",
      TRANSITIONS: { ...TRANSITIONS.easeIn },
    },
  },
  rise: {
    initial: { y: "110%" },
    animate: {
      y: "0",
      transition: TRANSITIONS.easeOut,
    },
    exit: { y: "110%", transition: TRANSITIONS.easeOut },
  },
  fall: {
    initial: { y: "-100%" },
    animate: {
      y: "0",
      transition: { ...TRANSITIONS.easeOut, delay: 0.7 },
    },
    exit: { y: "-100%", transition: TRANSITIONS.easeOut },
  },
};

export default VARIANTS;
