import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Link, Switch, useNavigate } from "react-router-dom";
import { Route } from "react-router";

//components
import ThemeToggle from "../../ThemeToggle";
//utils
// import TRANSITIONS from "../utils/transitions";
// import VARIANTS from "../utils/variants";
import ARTS from "../../../utils/arts";
//local styles
import styles from "./Artworks.module.css";

function Artworks(props) {
  const navigate = useNavigate();
  const artItems = ARTS;

  return (
    <div className={`${styles.container} drilldown-page`}>
      <div className={`${styles.artworksContainer} `}>
        {artItems.map((item) => (
          <Link
            key={item.id}
            to={item.url}
            className={`${styles.artItemContainer} `}
          >
            <div className={`${styles.imgContent} `}>
              <img
                alt="image"
                alt="image"
                src={process.env.PUBLIC_URL + item.imgPath}
              />
            </div>
            <div className={`${styles.itemText} `}>
              <div className={`${styles.title}`}>{item.title}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Artworks;
