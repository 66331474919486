import React, { lazy, Suspense, useState, useEffect, useRef } from "react";

import { CSSTransition } from "react-transition-group";

import {
  motion,
  useSpring,
  useTransform,
  AnimatePresence,
  AnimatedSharedLayout,
} from "framer-motion/dist/framer-motion";
//components
import WorkHeader from "./WorkHeader";

import WorkA from "./work-content/WorkA";
import WorkB from "./work-content/WorkB";
import WorkD from "./work-content/WorkD";
import WorkE from "./work-content/WorkE";
import WorkC from "./work-content/WorkC";
import WorkF from "./work-content/WorkF";
import WorkG from "./work-content/WorkG";
import WorkH from "./work-content/WorkH";
import WorkI from "./work-content/WorkI";
import WorkJ from "./work-content/WorkJ";
import WorkK from "./work-content/WorkK";
import WorkL from "./work-content/WorkL";
import WorkM from "./work-content/WorkM";

import LoaderHOC from "../../hocs/LoaderHOC";
//utils
import TRANSITION from "../../utils/transitions";
import VARIANTS from "../../utils/variants";
//local styles
import styles from "./WorkDetails.module.css";

function WorkDetails({ items, item }) {
  const variants = VARIANTS;

  const componentRegistry = {
    WorkA: <WorkA item={items.find((wObj) => wObj.component === "WorkA")} />,
    WorkB: <WorkB item={items.find((wObj) => wObj.component === "WorkB")} />,
    WorkD: <WorkD item={items.find((wObj) => wObj.component === "WorkD")} />,
    WorkE: <WorkE item={items.find((wObj) => wObj.component === "WorkE")} />,
    WorkC: <WorkC item={items.find((wObj) => wObj.component === "WorkC")} />,
    WorkF: <WorkF item={items.find((wObj) => wObj.component === "WorkF")} />,
    WorkG: <WorkG item={items.find((wObj) => wObj.component === "WorkG")} />,
    WorkH: <WorkH item={items.find((wObj) => wObj.component === "WorkH")} />,
    WorkI: <WorkI item={items.find((wObj) => wObj.component === "WorkI")} />,
    WorkJ: <WorkJ item={items.find((wObj) => wObj.component === "WorkJ")} />,
    WorkK: <WorkK item={items.find((wObj) => wObj.component === "WorkK")} />,
    WorkL: <WorkL item={items.find((wObj) => wObj.component === "WorkL")} />,
    WorkM: <WorkM item={items.find((wObj) => wObj.component === "WorkM")} />,
  };

  return (
    <div className={`${styles.container}  `}>
      <div className={`${styles.header}`}>
        <WorkHeader items={items} item={item} />
      </div>

      <motion.div className={`${styles.itemMedia} padding`}>
        <div className={`${styles.media} o-h`} key={item.url}>
          {item.mediaPath.slice(-3) === "mp4" ? (
            <video
              src={item.mediaPath}
              className={`${styles.itemMediaContent}`}
              preload="auto"
              autoPlay={1}
              loop={1}
              playsInline={1}
              webkit-playsinline="1"
            ></video>
          ) : (
            <img
              alt="image"
              className={`${styles.itemMediaContent}`}
              src={item.mediaPath}
            />
          )}
        </div>

        <div className={`${styles.metaDataWrapper} `}>
          <div className={`${styles.metaData}  `}>
            <div className={`ta-j`}>{item.overview}</div>
            <div className={`${styles.dataGrid}  grid no-padding font-caption`}>
              <div className={`r-1-c-1-rsp-4-csp-6 `}>
                <div className={`${styles.key}    opac-mid`}>Platform</div>
                <div className={`${styles.value}`}>{item.platform}</div>
              </div>
              <div className={`r-1-c-7-rsp-4-csp-6 `}>
                <div className={`${styles.key}   opac-mid`}>Role</div>
                <div className={`${styles.value}`}>{item.role}</div>
              </div>
              <div className={`r-5-c-1-rsp-4-csp-6 `}>
                <div className={`${styles.key}    opac-mid`}>Timeline</div>
                <div className={`${styles.value}   `}>{item.timeline}</div>
              </div>
              <div className={`r-5-c-7-rsp-4-csp-6 `}>
                <div className={`${styles.key}   opac-mid`}>Worked with</div>
                <div className={`${styles.value} `}>{item.collab}</div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <div className={`${styles.details} padding `}>
        {componentRegistry[item.component]}
      </div>
    </div>
  );
}

export default WorkDetails;
