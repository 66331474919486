import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  motion,
  useSpring,
  useTransform,
  AnimatePresence,
  AnimatedSharedLayout,
} from "framer-motion/dist/framer-motion";

//components
import LoaderHOC from "../hocs/LoaderHOC";
//utils
import MAGIC from "../utils/magic";
//local styles
import styles from "./Magic.module.css";

function Magic({ linkEnter, linkLeave, extLinkEnter, extLinkLeave }) {
  const magic = MAGIC;

  const [magicFrom, setMagicFrom] = useState(null);

  return (
    <div className={`${styles.container} `}>
      <div
        className={`${styles.magicText} anchor-div font-heading-2-hero ta-c`}
        id="magic"
      >
        <div className={`ta-c opac-lo font-caption padding`}>
          Hover the tool
        </div>
        Playing&nbsp;
        <span
          onMouseOver={() => setMagicFrom("figma")}
          onMouseOut={() => setMagicFrom(null)}
          className={` link flex flex-jc-fs flex-ai-fs`}
        >
          <span>Figma</span>
          <span className="font-caption ">&nbsp;30+</span>
        </span>
        &nbsp;/&nbsp;
        <span
          onMouseOver={() => setMagicFrom("b3d")}
          onMouseOut={() => setMagicFrom(null)}
          className={` link flex flex-jc-fs flex-ai-fs`}
        >
          <span>Blender</span>
          <span className="font-caption ">&nbsp;7</span>
        </span>
        &nbsp;/&nbsp;
        <span
          onMouseOver={() => setMagicFrom("ps")}
          onMouseOut={() => setMagicFrom(null)}
          className={` link flex flex-jc-fs flex-ai-fs`}
        >
          <span>Photoshop</span>
          <span className="font-caption ">&nbsp;20+</span>
        </span>
        &nbsp;to do magic, witness wonders, push & break the tool —{" "}
        <span>
          <a
            href="https://www.bento.me/samarthjajodia"
            className={`link flex flex-jc-fs flex-ai-fs`}
          >
            <span>People love it</span>
            <span className="font-caption ">&nbsp;↗</span>{" "}
          </a>
        </span>
      </div>

      <div
        className={`${styles.magicGallery} padding flex flex-wrap flex-ai-fs flex-jc-sa gap `}
      >
        {magic
          .sort((a, b) => a.id - b.id)
          .map((item, i) => (
            <div
              className={`${styles.magicMedia}  o-h`}
              key={item.id}
              style={
                item.filter === magicFrom || !magicFrom
                  ? {
                      opacity: "1",
                      filter: "blur(calc(0 * var(--spacing))",
                      transform: "scale(1)",
                      transitionDelay: "calc(" + i + " * 50ms)",
                    }
                  : {
                      opacity: "var(--opac-hi)",
                      filter: "blur(calc(2 * var(--spacing))",
                      transform: "scale(0.9)",
                      transitionDelay: "calc(" + i + " * 10ms)",
                    }
              }
            >
              {item.mediaPath.slice(-3) === "mp4" ? (
                <video
                  src={item.mediaPath}
                  className={`${styles.itemMediaContent}`}
                  preload="auto"
                  autoPlay={1}
                  loop={1}
                  playsInline={1}
                  webkit-playsinline="1"
                  muted={1}
                ></video>
              ) : (
                <img
                  alt={"an artsy cookie " + item.id + 1}
                  className={`${styles.itemMediaContent}`}
                  src={item.mediaPath}
                />
              )}
            </div>
          ))}
      </div>
      <div className={`${styles.magicLink} font-caption padding ta-c flex`}>
        <a
          href="https://bento.me/samarthjajodia"
          target="_blank"
          className="link"
        >
          [ View More ]
        </a>
      </div>
    </div>
  );
}

export default Magic;
