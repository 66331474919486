import React, { lazy, Suspense, useState, useEffect, useRef } from "react";

import { CSSTransition } from "react-transition-group";

import {
  motion,
  useSpring,
  useTransform,
  AnimatePresence,
  AnimatedSharedLayout,
} from "framer-motion/dist/framer-motion";
//components
import MeHeader from "./MeHeader";

import Info from "./me-content/Info";
import FigmaMagic from "./me-content/FigmaMagic";
import Artworks from "./me-content/Artworks";
import Archive from "./me-content/Archive";
import Links from "./me-content/Links";
import Toolkit from "./me-content/Toolkit";

import LoaderHOC from "../../hocs/LoaderHOC";
//utils
import VARIANTS from "../../utils/variants";
//local styles
import styles from "./MeDetails.module.css";

function MeDetails({ items, item }) {
  const variants = VARIANTS;

  const componentRegistry = {
    Info: <Info />,
    FigmaMagic: <FigmaMagic />,
    Artworks: <Artworks />,
    Archive: <Archive />,
    Toolkit: <Toolkit />,
    Links: <Links />,
  };

  return (
    <div className={`${styles.container} `}>
      <div className={`${styles.header} `}>
        <MeHeader item={item} items={items} />
      </div>

      <div className={`${styles.details} padding`}>
        {componentRegistry[item.component]}
      </div>
    </div>
  );
}

export default MeDetails;
