import React from "react";
import styles from "./WorkA.module.css";
import LoaderHOC from "../../../hocs/LoaderHOC";
function WorkA(props) {
  return (
    <div className={`${styles.container} drilldown-page`}>
      <div className={`heading font-heading-2`}>Research</div>

      <div className={``}>
        Understanding the user was neccessary but in order to deliver a seamless
        user experience it was crucial for us to understand how management at
        manufacturing factory works. This will give us more clarity on the tasks
        that need to be accomplished with the help of the new digital system and
        designed a manufacturing organisational chart to visualize the roles of
        our target users that they perform within the hierarchy.
      </div>

      <p className={``}>
        Keeping in mind the research goals, I gathered more information about
        the structure of the company,
      </p>

      {/* img-divider */}
      <p className={``}>
        <img
          alt="image"
          src={
            process.env.PUBLIC_URL + "/assets/works/p-mm/business structure.svg"
          }
        />
      </p>
      {/* section-divider */}
      <div className={`flexi-container`}>
        <div className={`heading fb-40`}>
          <p className={`font-heading-2`}>The Problem</p>
        </div>
        <div className={`body fb-60`}>
          <p className={`font-quote`}>
            "Supervisors and foremen working at a manufacturing factory want to
            effectively plan, manage and track projects and resources"
          </p>
          <p className={``}>
            — One of the stakeholders with whom I had a converstation.
          </p>
          <p className={`body-heading font-heading-3`}>Collaboration</p>
          <p className={``}>
            Proper collaboration within organisation was the key in order to
            speed up the entire manufacturing management process.
          </p>
          <p className={`body-heading font-heading-3`}>Digitisation</p>
          <p className={``}>
            Bring the end-to-end process and hence the complete user journey
            involved in manufacturing a product into a single mobile app.
          </p>

          <p className={`body-heading font-heading-3`}>Tracking</p>
          <p className={``}>
            Design a system that can track everything starting from employee
            attendance to the project they are involved in creating.
          </p>
        </div>
      </div>
      {/* section-divider */}
      <div className={`flexi-container`}>
        <div className={`heading fb-40`}>
          <p className={`font-heading-2`}>Role-based personas</p>
        </div>
        <div className={`body fb-60`}>
          <p className={``}>
            We needed a persona specifically for the employees rather than a
            wider audience and the application was going to be used internally
            rather than on a public domain.
          </p>
        </div>
      </div>
      {/* section-divider */}
    </div>
  );
}

export default WorkA;
