import React from "react";
import styles from "./WorkK.module.css";
import LoaderHOC from "../../../hocs/LoaderHOC";

function WorkK(props) {
  return (
    <div className={`${styles.container} drilldown-page`}>
      <div className={`heading font-heading-2`}>About</div>
      <div className={` `}>
        HighRadius has built an AI-enabled collections software that helps
        Accounts Receivables departments of HighRadius' clients to conveniently
        collect payment from their customers by automating various tasks such as
        giving payment reminders, sending automated follow-ups, detecting and
        analysing customer payment behaviour based on which A/R analysts can set
        up further action items, and more. Now this design language and
        including Notification components is being utilised in various other
        HighRadius products like Autonomous Accounting.
      </div>

      <div className={`font-body-small opac-hi`}>
        Due to company's privacy policy, I can't share every juicy detail so
        I've modified some bits like texts, colours, etc.
      </div>
      <div className={`heading font-heading-2`}>Context</div>
      <div className={` `}>
        Soon after design interning at HighRadius for a few weeks, I was given
        the opportunity to work on use cases for their highly profitable
        product, Autonomous Finance, targeted to perform as SaaS for enterprise
        market. Being in my initial days of growing as a designer and working in
        a fast-paced environment, I had to keep in mind that the designed
        solutions should strike the balance between business goals and user
        needs.
      </div>
      <div className={`heading font-heading-2`}>Project Goal</div>
      <div className={` `}>
        The PM team created a new story for the sprint stating that we needed to
        come up with notifications for the product especially concerning on a
        few major features that are highly used by the customers. As this story
        was assigned...
      </div>
      <div className={`heading font-body-heading`}>.&nbsp;.&nbsp;.&nbsp;</div>
      <div className={`heading font-heading-2`}>Design Principles</div>
      <div className={` `}>
        After presenting the draft internally, I laid out a few basic principles
        to guide me further through the design process. Aligning with these
        principles helped me to design select a toaster notification by
        narrowing down from the iterations...
      </div>
      <div className={`image`}>
        <img
          alt="image"
          src={
            process.env.PUBLIC_URL +
            "/assets/works/p-auto-cls-notif/auto_cls_notif.webp"
          }
        />
      </div>
      <div className={``}>
        <a
          href="https://read.cv/samarthjajodia/jyPhaGLMwOGIcAQHnFlu"
          target="_blank"
          className={`link font-caption-bold`}
        >
          read more&nbsp;
        </a>
        <span className={`font-caption-bold opac-mid`}>&nbsp;Protected</span>
      </div>
    </div>
  );
}
export default WorkK;
