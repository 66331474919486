const LINKS = [
  {
    id: 0,
    name: "Figma",
    link: "https://www.figma.com/@samarthjajodia",
    description: "Delicious design with magical secrets",
    category: "Primary",
    logoUrl: "https://cdn.cdnlogo.com/logos/f/43/figma.svg",
  },
  {
    id: 3,
    name: "Read.cv",
    link: "https://www.read.cv/samarthjajodia",
    description: "The coolest curriculum vitae with NDA works",
    category: "Primary",
    logoUrl:
      "https://uploads-ssl.webflow.com/62016deaf2189207a9b1784c/627012ff5aca2e86fb271203_read%20cv.svg",
  },
  {
    id: 9,
    name: "Medium",
    link: "https://www.medium.com/@samarthjajodia",
    description: "I write on how I design for human",
    category: "Primary",
    logoUrl: "https://cdn.cdnlogo.com/logos/m/21/monogram-medium.svg",
  },
  {
    id: 4,
    name: "Bento",
    link: "https://www.bento.me/samarthjajodia",
    description: "My work and links in cool widgets",
    category: "Primary",
    logoUrl:
      "https://global-uploads.webflow.com/6335b33630f88833a92915fc/63860225045ce50e33d20eb3_Logo.svg",
  },
  {
    id: 1,
    name: "Dribbble",
    link: "https://www.dribbble.com/samarthjajodia",
    description: "Some blender renders and digital artworks",
    category: "More",
    logoUrl: "https://cdn.cdnlogo.com/logos/d/56/dribbble-icon.svg",
  },
  {
    id: 11,
    name: "Behance",
    link: "https://www.behance.com/samarthjajodia",
    description: "An archive worth to behold",
    category: "More",
    logoUrl: "https://cdn.cdnlogo.com/logos/b/29/behance.svg",
  },
  {
    id: 4,
    name: "Instagram",
    link: "https://www.instagram.com/samarthjajodia",
    description: "Art, magic, recipies, more",
    category: "Primary",
    logoUrl: "https://cdn.cdnlogo.com/logos/i/92/instagram.svg",
  },
  {
    id: 5,
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/samarthjajodia",
    description: "Let's talk design, art, & products",
    category: "Primary",
    logoUrl: "https://cdn.cdnlogo.com/logos/l/66/linkedin-icon.svg",
  },
  {
    id: 6,
    name: "NFT.WazirX",
    link: "https://nft-archive.wazirx.org/samarthjajodia/created?sort=created-desc",
    description: "Sold some cool artsy pieces here",
    category: "NFT",
    logoUrl: "https://wazirx.com/logo.png",
  },
  {
    id: 7,
    name: "Youtube",
    link: "https://youtube.com/@samarthjajodia",
    description: "Recipe for my delicious Figma cookies",
    category: "Primary",
    logoUrl: "https://cdn.cdnlogo.com/logos/y/57/youtube-icon.svg",
  },
  // {
  //   id: 8,
  //   name: "Gumroad",
  //   link: "https://gumroad.com/samarthjajodia",
  //   description: "Go grab some cool stuff here",
  //   category: "Primary",
  //   logoUrl:
  //     "https://assets-global.website-files.com/6171b265e5c8aa59b42c3472/618f28a89e94524552a002cb_g-icon.svg",
  // },
  {
    id: 12,
    name: "Buy me a coffee",
    link: "https://buymeacoffee.com/samarthjajodia",
    description: "Lattos and hot chocolates keep me moving",
    category: "Primary",
    logoUrl:
      "https://www.buymeacoffee.com/assets/img/guidelines/logo-mark-3.svg",
  },
  {
    id: 11,
    name: "Twitter",
    link: "https://twitter.com/@samarthjajodia",
    description: "Say hello, with the intention to vibe on design",
    category: "Primary",
    logoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/6/6f/Logo_of_Twitter.svg",
  },
  // {
  //   id: 10,
  //   name: "topmate",
  //   link: "https://twitter.com/@samarthjajodia",
  //   description: "Say hello, with the intention to vibe on design",
  //   category: "Primary",
  //   logoUrl: "https://cdn.cdnlogo.com/logos/t/48/twitter.svg",
  // },
  {
    id: 2,
    name: "Topmate",
    link: "https://topmate.io/samarthjajodia",
    description: "Book 1:1 calls to ask, share, get assistance",
    category: "Primary",
    logoUrl: "https://topmate.io/favicon.svg",
  },
];
export default LINKS;
