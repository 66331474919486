import React, { useState, useEffect } from "react";
import {
  motion,
  useSpring,
  useTransform,
  AnimateSharedLayout,
  AnimatePresence,
} from "framer-motion/dist/framer-motion";

import VARIANTS from "../utils/variants";
import styles from "./Preloader.module.css";

function Preloader({ percentage }) {
  const variants = VARIANTS;
  const [animatedPercentage, setAnimatedPercentage] = useState(0);

  useEffect(() => {
    let i = animatedPercentage; // Start from the current animated percentage
    const animationInterval = setInterval(() => {
      if (i <= percentage) {
        setAnimatedPercentage(i++);
      }
    }, 20);

    return () => {
      clearInterval(animationInterval);
    };
  }, [percentage]);

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div className={`${styles.container} h-100 w-100`}>
        <motion.div
          className={`${styles.name} font-heading-3 t-s  w-100 flex pos-f h-60 `}
          variants={variants.ascend}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          Samarth Jajodia
        </motion.div>
        <div className={`${styles.loaded} pos-a font-caption w-100 ta-c`}>
          <div className={`${styles.percentage}`}>
            {Math.round(animatedPercentage)}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default Preloader;
