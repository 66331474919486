import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  Routes,
  Outlet,
  useParams,
  useNavigate,
  Navigate,
  Route,
} from "react-router-dom";
import {
  motion,
  useSpring,
  useTransform,
  AnimatePresence,
} from "framer-motion/dist/framer-motion";

//components
import Gallery from "./Gallery";
import Magic from "./Magic";

//utils
import TRANSITIONS from "../utils/transitions";
import VARIANTS from "../utils/variants";
//local styles
import styles from "./Home.module.css";
import LoaderHOC from "../hocs/LoaderHOC";

//using locomotive scroll causing an issue on fixed overlay modals.

function Home({ linkEnter, linkLeave, extLinkEnter, extLinkLeave, gallery }) {
  const variants = VARIANTS;
  const location = useLocation();
  //counter
  const occupancy = [
    "a curious creative",
    "designing for human",
    "an engineer ",
    "an artist",
  ];
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) =>
        prevCounter < occupancy.length - 1 ? prevCounter + 1 : (prevCounter = 0)
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`${styles.container} `}>
      {/* hero text */}
      <div
        className={`${styles.heroContainer} pos-f`}
        style={
          gallery
            ? {
                opacity: "1",
                filter: "blur(0)",
                transform: "translate(0, 0) scale(1)",
              }
            : {
                opacity: "0",
                filter: "blur(var(--spacing))",
                transform: "translate(0, -30%) scale(0.95)",
              }
        }
      >
        <motion.div className={`${styles.heroTitle} ta-c tt-uc`}>
          <div className={``}>{occupancy[counter]}</div>
          crafting humane experiences and narratives through system-scalable
          interfaces &amp; creative artefacts—with magic
        </motion.div>
      </div>

      <div className={`${styles.gallery} w-100 `}>
        <div
          className={`${styles.catchLine} pos-f ta-c padding font-caption opac-uhi `}
          style={
            gallery
              ? {
                  opacity: "1",
                  filter: "blur(0)",
                }
              : {
                  opacity: "0",
                  filter: "blur(var(--spacing))",
                }
          }
        >
          while it's fun to code, write and mentor
        </div>
        <Gallery gallery={gallery} />
      </div>
      <div className={`${styles.magic} w-100`}>
        <Magic />
      </div>
    </div>
  );
}

export default Home;
