import React, { useRef, useEffect, useLayoutEffect, useState } from "react";

//local styles
import styles from "./Spinner.module.css";

function Spinner() {
  return (
    <div className={`${styles.container} padding pos-a `}>
      <div className={`${styles.spinnerWrapper} `}>
        <div className={`${styles.spinner1} pos-a`}>
          <div className={`${styles.path}`}></div>
        </div>
        <div className={`${styles.spinner2} pos-a`}>
          <div className={`${styles.path}`}></div>
        </div>
        <div className={`${styles.spinner3}`}>
          <div className={`${styles.path}`}></div>
        </div>
      </div>
    </div>
  );
}

export default Spinner;
