const WORKS = [
  {
    id: 3,
    url: "highradius-dashboards",
    title: "dotONE Performance & RadiusONE",
    type: "UX Case Study",
    description:
      "pushing Notifications to make A/R departments stay more informed and productive",
    overview:
      "Designed visually upgraded dashboards by translating information design into data-heavy charts, derived from a data visualization library, the creation of which I assisted.",
    timeline: "Jul - Sep 2020",
    year: ["2020"],
    collab: "Senior Designer, Product Manager, Design Manager",
    platform: "Web (Touch & Voice)",
    status: "WIP",
    role: "UX Writing, Data Visualisation, Visual Design",

    mediaPath: "/assets/works/gallery/hrc-dashboards.webp",
    component: null,
    extUrl: "https://read.cv/samarthjajodia/EGv5KVwmX4ciWKcN0ouJ",
    protected: 1,
    bgColor: "#D53D9E",
  },

  {
    id: 0,
    url: "designing-for-purplle",

    title: "Purplle Beauty",
    type: "UX Case Study",
    description:
      "solving for product design challenge, driving business through design",
    overview:
      "How I introduced a new feature to help users improve consistency in maintaining their beauty & personal care routine, while increasing customer retention and boosting sales.",
    timeline: "Apr 2023 (48-hours)",
    year: ["2023"],
    collab: "(Sole)",
    platform: "Android (Native)",
    status: "New",
    role: "Research, Wireframing, Interaction/Visual Design",

    mediaPath: "/assets/works/gallery/purplle.webp",
    component: "WorkH",
    extUrl: null,
    protected: null,
    bgColor: "#FF8E26",
  },
  {
    id: 6,
    url: "porfolio-t1-ui",

    title: "Portfolio T1 UI",
    type: "UX Case Study",
    description:
      "solving for product design challenge, driving business through design",
    overview:
      "How I designed & coded my portfolio, a PWA with multiple themes that has been curiously crafted into something I truly admire. With countless hours of brainstorming and building scenarios on a scalable UI, it's a cool showcase of my work with magic, emerging from a blank art board & codebase.",
    timeline: "Apr 2023 - Jun 2023",
    year: ["2023"],
    collab: "(Sole)",
    platform: "Web (Mobile-first responsive & Touch)",
    status: "New",
    role: "Research, Wireframing, Interaction/Visual/Motion Design, Frontend Development",

    mediaPath: "/assets/works/gallery/portfolio-website.gif",
    component: null,
    extUrl: null,
    protected: null,
    bgColor: "#FF8E26",
  },

  {
    id: 2,
    url: "highradius-paynremit",
    title: "HighRadius PayNRemit",
    type: "UX Case Study",
    description:
      "redesigning the mobile experience to settle open A/R invoices in B2B world",
    overview:
      "In charge of designing a fresh experience for supplier mobile app to improve its usability across core touchpoints like navigation, filter, payment with overall visual redesign to align with new design language thereby reducing customer churn.",
    timeline: "Oct-Dec 2020",
    year: ["2020"],
    collab: "Product Manager, Senior Software Engineer",
    platform: "Android (Native)",
    status: null,
    role: "UX Design, Interaction/Visual Design",

    mediaPath: "/assets/works/gallery/hrc-pnr.webp",
    component: "WorkF",
    extUrl: null,
    protected: null,
    bgColor: "#fc7500",
  },

  {
    id: 4,
    url: "discord-save-message-ux",
    title: "Discord",
    type: "UX Case Study",
    overview:
      "Evaluated Discord user communication flows to craft a new experience that enables them to save community messages on a server level. A feature potential enough to remarkably save user's time & efforts.",
    description: "designing ‘Save’ message experience",
    timeline: "Jan 2023",
    year: ["2023"],
    collab: "(Sole)",
    platform: "Android (Native)",
    status: "New",
    role: "UX Design, Visual Design",

    mediaPath: "/assets/works/gallery/discord.webp",
    component: null,
    extUrl:
      "https://medium.com/design-bootcamp/discord-ux-enhancement-156ac9c89d76",
    protected: null,
    bgColor: "#5762F6",
  },

  {
    id: 1,
    url: "highradius-autononous-accounting",

    title: "Autonomous Accounting",
    type: "UX Case Study",
    description:
      "designing an AI-based R2R automation app for the Office of the CFO",
    overview:
      "Owned & led a team to design Autonomous Accounting product line, from kickstarting design vision to product maturity. Worked closely with CXOs, SMEs to ship 100+ features, e2e flows that automated R2R processes to help businesses reduce 80% manual work, improve reconciliation accuracy by 90%, detect & resolve anomalies.",
    timeline: "May 2021 - Dec 2022",
    year: ["2021", "2022"],
    collab:
      "Product & UX VP, Product Strategists, Senior Product Manager, UX Director, Product Analysts, Product Director, Developers, Design Interns",
    platform: "Web",
    status: "New",
    role: "Product Design, Design System",

    mediaPath: "/assets/works/gallery/hrc-aa.gif",
    component: null,
    extUrl: "https://read.cv/samarthjajodia/J2E63OAbVNFd7f8A8qGH",
    protected: 1,
    bgColor: "#FF8E26",
  },
  {
    id: 5,
    url: "highradius-autonomous-receivables",

    title: "Autonomous Receivables",
    type: "UX Case Study",
    description:
      "an year of designing to fasten A/R automation for a touch- & voice-based AI-enabled product",
    overview:
      "Designed core features for Autonomous Receivables, product with touch & voice interface — scenarios like calling, post-call actions, timelines, conversational UX for virtual AI assistance, notifications system to customer retention, satisfaction, and loyalty.",
    timeline: "Dec 2020 — Oct 2021",
    year: ["2020", "2021"],
    collab:
      "Senior Product Managers, Senior Developers, Senior UX Designer, Associate UX Designer",
    platform: "Web (Touch & Voice)",
    status: "New",
    role: "Research, UX Writing, Interaction/Visual Design",

    mediaPath: "/assets/works/gallery/hrc-auto-cls.webp",
    component: null,
    extUrl: "https://read.cv/samarthjajodia/NBQYqrVmSYsY4asf9qFu",
    protected: 1,
    bgColor: "#FF8E26",
  },
];

export default WORKS;
