import React, { useEffect, useState, useRef } from "react";

import {
  NavLink,
  Link,
  Switch,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  motion,
  useSpring,
  useTransform,
  AnimatePresence,
  AnimatedSharedLayout,
} from "framer-motion/dist/framer-motion";

//components
import ThemeToggle from "../ThemeToggle";
//utils
import TRANSITIONS from "../../utils/transitions";
import VARIANTS from "../../utils/variants";
//local styles
import styles from "./MeHeader.module.css";

function WorkHeader({ items, item }) {
  const transitions = TRANSITIONS;
  const variants = VARIANTS;
  const navigate = useNavigate();

  const [header, showHeader] = useState(1);
  const [position, setPosition] = useState(window.scrollY);

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.scrollY;
      showHeader(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <div className={`${styles.container} flex flex-col flex-jc-sb`}>
      <div
        className={`${styles.controlBar} font-caption-bold flex flex-col  flex-jc-fe flex-ai-fe gap`}
      >
        {/* close */}
        <br />
        <br />
        <div
          className={`${styles.control} link  `}
          onClick={() => {
            navigate(-1, { replace: 1 });
            // showGallery(0);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          {/* <VscClose /> */}Close
        </div>
      </div>

      <div
        className={`${styles.infoContainer} padding flex  flex-col gap`}
        style={
          !header
            ? {
                opacity: 0,
                filter: "blur(calc(0.5 * var(--spacing)))",
              }
            : {
                opacity: "1",
                filter: "blur(calc(0 * var(--spacing)))",
              }
        }
      >
        <div className={`${styles.infoTitle}  ta-c font-heading-1 `}>
          <div className={`${styles.titleText}  `}>{item.title}</div>
        </div>

        <div className={`${styles.infoDesc} ta-c`}>
          <div className={`${styles.descText}  font-body-serif`}>
            {item.description}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkHeader;
