const ARTS = [
  {
    id: 0,
    title: "Purpose 2",
    desc: "What's her purpose",
    keys: ["her", "featured"],
    imgPath: "/assets/arts/art10.webp",
    imgPathLowRes: "/assets/arts/low-res/art10.webp",
    url: "purpose-2",
    cssGridCol: "1 / span 2",
    cssGridRow: "1",
  },
  {
    id: 1,
    title: "Purpose 1",
    desc: "What’s her purpose",
    keys: ["her", "featured"],
    imgPath: "/assets/arts/art1.webp",
    imgPathLowRes: "/assets/arts/low-res/art1.webp",
    url: "purpose-1",
    cssGridCol: "3 / span 2",
    cssGridRow: "1",
  },
  {
    id: 2,
    title: "State of Coherence",
    desc: "They say consistency is the key. Do you also feel that you are losing coherence with the people of a unified world made so ideally perfect by today’s generalists?",
    keys: ["mess & states", "featured"],
    imgPath: "/assets/arts/art2.webp",
    imgPathLowRes: "/assets/arts/low-res/art2.webp",
    url: "state-of-coherence",
    cssGridCol: "5 / span 2",
    cssGridRow: "1",
  },
  {
    id: 3,
    title: "State of Anguish",
    desc: "Messed up in a world full of corporates setting the bar and people from all races constantly trying to raise it, feels like so much of anguish.",
    keys: ["mess & states", "featured"],
    imgPath: "/assets/arts/art3.webp",
    imgPathLowRes: "/assets/arts/low-res/art3.webp",
    url: "state-of-anguish",

    cssGridCol: "7 / span 2",
    cssGridRow: "1 ",
  },
  {
    id: 4,
    title: "Golden Curlicue",
    desc: "Bare beginnings with Blender for the love of colors.",
    keys: ["curlicue & colors"],
    imgPath: "/assets/arts/art4.webp",
    imgPathLowRes: "/assets/arts/low-res/art4.webp",
    url: "golden-curlicue",
    cssGridCol: "1 / span 2",
    cssGridRow: "2",
  },
  {
    id: 5,
    title: "Copper Curlicue",
    desc: "Bare beginnings with Blender for the love of colors.",
    keys: ["curlicue & colors"],
    imgPath: "/assets/arts/art5.webp",
    imgPathLowRes: "/assets/arts/low-res/art5.webp",
    url: "purpose",
    cssGridCol: "3 / span 2",
    cssGridRow: "2 ",
  },
  {
    id: 6,
    title: "Turquoise Curlicue",
    desc: "Bare beginnings with Blender for the love of colors.",
    keys: ["curlicue & colors"],
    imgPath: "/assets/arts/art6.webp",
    imgPathLowRes: "/assets/arts/low-res/art6.webp",
    url: "turquoise-curlicue",
    cssGridCol: "5 / span 2",
    cssGridRow: "2 ",
  },
  {
    id: 7,
    title: "Renaissance",
    desc: "She’ll love. She’ll fight. She’ll not let go. It’ll be her rebirth because she’ll always stay. ",
    keys: ["her"],
    imgPath: "/assets/arts/art7.webp",
    imgPathLowRes: "/assets/arts/low-res/art7.webp",
    url: "renaissance",
    cssGridCol: "7 / span 2",
    cssGridRow: "2 ",
  },
  {
    id: 8,
    title: "Bravery",
    desc: "She’s brave. No fear. No regrets.",
    keys: ["her"],
    imgPath: "/assets/arts/art8.webp",
    imgPathLowRes: "/assets/arts/low-res/art8.webp",
    url: "bravery",
    cssGridCol: "1 / span 2",
    cssGridRow: "3",
  },
  {
    id: 9,
    title: "Atomic",
    desc: "The roots of the world are from atoms.",
    keys: ["IN NATURE"],
    imgPath: "/assets/arts/art9.webp",
    imgPathLowRes: "/assets/arts/low-res/art9.webp",
    url: "atomic",
    cssGridCol: "3 / span 2",
    cssGridRow: "3",
  },
];

export default ARTS;
