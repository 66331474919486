import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  motion,
  useSpring,
  useTransform,
  AnimatePresence,
  AnimatedSharedLayout,
} from "framer-motion/dist/framer-motion";

//components

//utils
import WORKS from "../utils/works";
import VARIANTS from "../utils/variants";
import TRANSITIONS from "../utils/transitions";
//local styles
import styles from "./Gallery.module.css";
import ARTS from "../utils/arts";

function Gallery({ gallery }) {
  const works = WORKS;
  const variants = VARIANTS;
  const transitions = TRANSITIONS;
  //the most scalable function built for gallery ever...
  const optimizeArr = (rawArr) =>
    rawArr
      .map((item) => (item.id % 2 === 0 ? 1 : 0))
      .filter(
        (i, index) =>
          rawArr.map((item) => (item.id % 2 === 0 ? 1 : 0)).indexOf(i) === index
      );

  // framer animations

  const gallerySetVars = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,

      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.8,
      },
    },
    exit: {
      opacity: 0,

      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const galleryItemVars = {
    initial: {
      opacity: 0,
      filter: "blur(calc(5 * var(--spacing)))",
      transform: "scale(2.2)",
    },
    animate: {
      opacity: 1,
      filter: "blur(calc(0 * var(--spacing)))",
      transform: "scale(1)",

      transition: { ...transitions.easeOut, duration: 0.5 },
    },
    exit: {
      opacity: 0,
      filter: "blur(calc(10 * var(--spacing)))",
      transform: "scale(0.4)",
      transition: transitions.easeOut,
    },
  };

  return (
    <div
      className={`${styles.container} `}
      style={
        gallery
          ? {
              transform: "translate(0, 0vh) scale(1)",
            }
          : {
              transform: "translate(0, -50vh) scale(1)",
            }
      }
    >
      {/* gallery start */}
      <div
        className={`${styles.galleryContainer} anchor-div padding`}
        id="works"
      >
        {works
          .sort((a, b) => a.id - b.id)
          .map((item) => (
            <div key={item.id} className={`${styles.galleryItem} `}>
              <motion.div className={`${styles.itemMedia} `}>
                <div className={`${styles.itemId} ta-c font-caption opac-hi`}>
                  0{item.id + 1} / 0{works.length}
                </div>
                <div className={`${styles.media} o-h`} key={item.url}>
                  {item.mediaPath.slice(-3) === "mp4" ? (
                    <video
                      src={item.mediaPath}
                      className={`${styles.itemMediaContent}`}
                      preload="auto"
                      autoPlay={1}
                      loop={1}
                      playsInline={1}
                      webkit-playsinline="1"
                    ></video>
                  ) : (
                    <img
                      alt={item.title + item.description}
                      className={`${styles.itemMediaContent}`}
                      src={item.mediaPath}
                    />
                  )}
                </div>

                <div
                  className={`${styles.itemTitle}  ta-c font-heading-2-hero tt-uc`}
                >
                  {item.title}
                </div>

                <div className={`${styles.metaDataWrapper} `}>
                  <div className={`${styles.metaData}  `}>
                    <div className={`ta-j_c`}>{item.overview}</div>
                    <div
                      className={`${styles.dataGrid}  grid no-padding font-caption`}
                    >
                      <div className={`r-1-c-1-rsp-4-csp-6 `}>
                        <div className={`${styles.key}    opac-mid`}>
                          Platform
                        </div>
                        <div className={`${styles.value}`}>{item.platform}</div>
                      </div>
                      <div className={`r-1-c-7-rsp-4-csp-6 `}>
                        <div className={`${styles.key}   opac-mid`}>Role</div>
                        <div className={`${styles.value}`}>{item.role}</div>
                      </div>
                      <div className={`r-5-c-1-rsp-4-csp-6 `}>
                        <div className={`${styles.key}    opac-mid`}>
                          Timeline
                        </div>
                        <div className={`${styles.value}   `}>
                          {item.timeline}
                        </div>
                      </div>
                      <div className={`r-5-c-7-rsp-4-csp-6 `}>
                        <div className={`${styles.key}   opac-mid`}>
                          Worked with
                        </div>
                        <div className={`${styles.value} `}>{item.collab}</div>
                      </div>
                    </div>
                    <div className={`${styles.itemLink} ta-c font-caption `}>
                      {!item.extUrl && !item.component ? (
                        <div className="opac-mid">Coming Soon</div>
                      ) : !item.extUrl ? (
                        <Link
                          to={`/${item.url}`}
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                            });
                          }}
                          className="link"
                        >
                          [ View Project ]
                        </Link>
                      ) : (
                        <a href={item.extUrl} target="_blank" className="link">
                          [ View Project ]
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          ))}
      </div>

      {/* gallery end */}
    </div>
  );
}

export default Gallery;
