import React from "react";
import ReactDOM from "react-dom";

import ThemeToggle from "./ThemeToggle";

const WithThemePersistence = () => {
  const storedTheme = localStorage.getItem("theme");
  document.documentElement.setAttribute("data-theme", storedTheme || "dark");

  return <ThemeToggle />;
};

export default WithThemePersistence;
