import React from "react";
import styles from "./WorkE.module.css";
import LoaderHOC from "../../../hocs/LoaderHOC";
function WorkE() {
  return (
    <div>
      <div className={`${styles.sectionDisc} `}>
        <div>
          You are currently viewing the following section as an
          image/video/figma since development is in progress.
        </div>
        Thanks for your patience. (Best viewed in Macbook 14")
      </div>
      <div className={`${styles.ss}`}>
        1. Process(Video) 2. Solution (Figma prototype; best viewed in Mobile)
      </div>
      <div className={`${styles.iframeTemp}`}>
        <div className={`${styles.tempItem}`}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/IE8pdkPwTBQ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoPlay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className={`${styles.figmaTemp}`}>
        <div className={`${styles.figmaTempItem}`}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F8goGxjcGnvhErGAVrSNCfs%2FImproving-%2522Whatsapp-for-Business%2522-for-Kirana-Stores%3Fnode-id%3D30%253A91%26viewport%3D241%252C48%252C0.27%26scaling%3Dmin-zoom%26starting-point-node-id%3D30%253A91%26hide-ui%3D1"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
export default WorkE;
