import { useState, useEffect } from "react";

export const useOnLoadImages = (ref) => {
  const [status, setStatus] = useState({
    status: false,
    loaded: 0,
    total: 0,
    percentage: 0,
  });

  useEffect(() => {
    const updateStatus = (images) => {
      const loadedImages = images.filter((i) => i.complete);
      const loadedCount = loadedImages.length;
      const totalCount = images.length;
      const percentage = (loadedCount / totalCount) * 100;

      setStatus({
        status: images.map((i) => i.complete).every((item) => item === true),
        loaded: loadedCount,
        total: totalCount,
        percentage,
      });
    };

    if (!ref.current) return;

    const imagesLoaded = Array.from(ref.current.querySelectorAll("img"));

    if (imagesLoaded.length === 0) {
      setStatus({ status: true, loaded: 0, total: 0, percentage: 100 });
      return;
    }

    imagesLoaded.forEach((image) => {
      image.addEventListener("load", () => updateStatus(imagesLoaded), {
        once: true,
      });
      image.addEventListener("error", () => updateStatus(imagesLoaded), {
        once: true,
      });
    });

    return;
  }, [ref]);

  return status;
};
