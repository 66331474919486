import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Link, Switch, useNavigate } from "react-router-dom";
import { Route } from "react-router";

//components
import ThemeToggle from "../../ThemeToggle";
//utils
// import TRANSITIONS from "../utils/transitions";
// import VARIANTS from "../utils/variants";

//local styles
import styles from "./FigmaMagic.module.css";

function FigmaMagic(props) {
  const navigate = useNavigate();

  return (
    <div className={`${styles.container} drilldown-page `}>
      <div className={`image`}>
        <video
          src={process.env.PUBLIC_URL + "/assets/figmamagic.mp4"}
          className={`${styles.itemMediaContent}`}
          preload="auto"
          autoPlay={1}
          loop={1}
          playsInline={1}
          webkit-playsinline="1"
          muted={1}
        ></video>
      </div>

      <div className={`w-100p ta-c`}>
        <a
          href="https://www.bento.me/samarthjajodia"
          target="_blank"
          className={`${styles.temp} font-caption-bold link `}
        >
          Click here to witness the magic with recipes full of secret
          ingredients ↗
        </a>
      </div>
    </div>
  );
}

export default FigmaMagic;
