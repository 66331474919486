import React from "react";
import styles from "./WorkD.module.css";
import LoaderHOC from "../../../hocs/LoaderHOC";
function WorkD() {
  return (
    <div className={`${styles.container} drilldown-page `}>
      <div className={`flexi-container`}>
        <div className={`heading fb-40`}>
          <p className={`font-heading-2`}>Context</p>
        </div>
        <div className={`body fb-60`}>
          <p className={``}>
            Bollywood Hills is a real estate company and they have new project
            namely Siddhivinayak Bollywood Hills which is readily available in
            Kamshet, Pune, India. Kamshet is a treasure trove of scenic places
            to visit and beautiful structures to explore where Bollywood Hills
            was offering plots and homes to buy. I felt this real estate project
            has a lot of potential in terms of customer acquisition thereby
            increasing my curiousity to take this challenge.
          </p>
        </div>
      </div>

      {/* section-divider */}
      <div className={`flexi-container`}>
        <div className={`heading fb-40`}>
          <p className={`font-heading-2`}>Problem</p>
        </div>
        <div className={`body fb-60`}>
          <p className={``}>
            A simple yet quite challenging assignment was to design a landing
            page from scratch. The information to show on the website was
            provided by the client. However, much emphasis was given on the
            contact/enquiry form because the prospect may not want to spend time
            to go through the website but put an enquiry as soon as possible.
          </p>
        </div>
      </div>
      {/* section-divider */}
      <div className={`flexi-container`}>
        <div className={`heading fb-40`}>
          <p className={`font-heading-2`}>Constraints</p>
        </div>
        <div className={`body fb-60`}>
          <p className={``}>
            1. Atleast one color needs to be dervided from the logo.
          </p>
          <p className={``}>
            2. Information heavy website, but need minimum vertical scrolls.
          </p>
          <p className={``}>
            3. Contact form must be highly prominent as the prospect may not
            have time to go through the whole website but put an enquiry as soon
            as possible.
          </p>
        </div>
      </div>
      {/* section-divider */}
      <div className={`flexi-container`}>
        <div className={`heading fb-40`}>
          <p className={`font-heading-2`}>The Problem Space</p>
        </div>
        <div className={`body fb-60`}>
          <p className={``}>
            PM team gave me the following statement —<br />
          </p>
          <p className={`font-quote`}>
            "Field sales representatives find it difficult to collect
            remittances from their customers. It's a very time-consuming process
            for them."
          </p>
          <p className={``}>
            Stating the above, they walked me through the entire application
            explaining the payment process and showed me various points where UX
            could be improved. With this, they gave me the task to run in
            parellel — visually uplift the application. Then I started using the
            application myself where I could clearly identify some of the root
            causes that were leading to unintended user problems.
          </p>

          <p className={`transparent`}>filler</p>
          <p className={``}>
            After subsequent discussions with the product management (who had
            customer feedbacks), engineering and other internal stakeholders I
            made a consolidated list of problems, solving of which could
            effectively improve the user experience to significant amount.
          </p>

          <p className={`body-heading font-heading-3 tt-uc0`}>
            (01) Point of no return
          </p>
          <p className={``}>
            Users have to start the payment process all over again if for some
            reason they move out of the payment flow (for eg., app crashing
            down). This is because the progress made so far is lost as the app
            doesn't keep any track of it.
          </p>
          <p className={`body-heading font-heading-3 tt-uc0`}>
            (02) Unclear entry and exit points
          </p>
          <p className={``}>
            When did the payment process start and after how many steps will the
            process take is not indicated in the application.
          </p>
          <p className={`body-heading font-heading-3 tt-uc0`}>
            (03) Difficult to search the customers
          </p>
          <p className={``}>
            Multiple customers (for different branches) can be selected and
            searching customer(s) out of an exaustive list is not easy here
            because the search feature is highly ineffective.
          </p>
          <p className={`body-heading font-heading-3 tt-uc0`}>
            (04) Consistency
          </p>
          <p className={``}>
            The visual design was highly inconsistent with the design language
            that I along with my team have recently established to standardize
            all Highradius products.
          </p>

          <p className={`body-heading font-heading-3 tt-uc0`}>
            (05) No emphasis, too much highlighting
          </p>
          <p className={``}>
            Every UI element starting from a text to a button was highlighted,
            so on a screen level it was difficult to figure out elements seeking
            more importance and those carrying less weight.
          </p>
          <p className={`body-heading font-heading-3 tt-uc0`}>
            (06) Content arrangement
          </p>
          <p className={``}>
            There was too much white space due to improper arrangement of UI
            elements. I won't say they were looking disproportionate, but
            overall viewport could have been utilized better.
          </p>
          <p className={`transparent`}>filler</p>
          <p className={``}>
            I could clearly see why we needed a visual redesign by just looking
            at the application. I realised that uplifting the visual design
            would play a paramount role in enhancing the overall user
            experience.
          </p>
        </div>
      </div>
      {/* section-divider */}
      <div className={`flexi-container`}>
        <div className={`heading fb-40`}>
          <p className={`font-heading-2`}>Constraints</p>
        </div>
        <div className={`body fb-60`}>
          <p className={``}>
            1. Users have a mental model for collecting remittances through this
            application. The upgraded expererience should not break the
            ecosystem.
          </p>
          <p className={``}>
            2. Given the project scope and timelines, components from the
            existing design language should be utilized with slight variations
            if at all needed.
          </p>
        </div>
      </div>
      {/* section-divider */}
      <div className={`flexi-container`}>
        <div className={`heading fb-40`}>
          <p className={`font-heading-2`}>Initial screens</p>
        </div>
        <div className={`body fb-60`}>
          <p className={``}>Below are screens before the design revamp.</p>
          <p className={``}>
            <img
              alt="image"
              src={
                process.env.PUBLIC_URL +
                "/assets/works/p-pnr/old-supplier-1.webp"
              }
            />
          </p>
          <p className={``}>
            <img
              alt="image"
              src={
                process.env.PUBLIC_URL +
                "/assets/works/p-pnr/old-supplier-2.webp"
              }
            />
          </p>
          <p className={``}>
            <img
              alt="image"
              src={
                process.env.PUBLIC_URL +
                "/assets/works/p-pnr/old-supplier-3.webp"
              }
            />
          </p>
        </div>
      </div>
      {/* section-divider */}
      <div className={`flexi-container highlight`}>
        <div className={`heading fb-40`}>
          <p className={`font-heading-2`}>Design Principles</p>
        </div>
        <div className={`body fb-60 `}>
          <p className={``}>
            Based on findings and insights gained till now I laid out some
            design principles that will help me to design accordingly driven by
            core fundamentals.
          </p>
          <p className={`body-heading font-heading-3 tt-uc0`}>
            (01) Design for Scale
          </p>
          <p className={``}>
            Probably, the quantity of information (eg. line items, entities,
            objects, rows, records, etc.) can grow as the B2B transactions
            increase.Generally, such data is fetched from backend and needs to
            be rendered on the UI without any performance issues in case the
            data scales up. So, we needed to design for scale.
          </p>
          <p className={`body-heading font-heading-3 tt-uc0`}>
            (02) Provide Context
          </p>
          <p className={``}>
            If the design helps the user to accomplish a certain task, the flow
            designed to complete the task should be self-explanatory. The user
            should be aware of steps already take and those yet to take. The
            design should help the user not to forget the actions taken
            previously. The user should feel that progress is being made.
          </p>
          <p className={`body-heading font-heading-3 tt-uc0`}>
            (03) Save from Human Error
          </p>
          <p className={``}>
            The design should always help users stay on the right path. If they
            are going act upon something by mistake or by intention, the design
            must inform them about the consequences of that action to help users
            make a better judgement.
          </p>

          <p className={`body-heading font-heading-3 tt-uc0 `}>
            (04) Eliminate Unneccessary Elements
          </p>
          <p className={``}>
            The user should not interact with an UI element be it a full screen
            or an icon, the presence of which does not make any sense. This will
            also help the user save some effort.
          </p>
        </div>
      </div>
      {/* section-divider */}
      <div className={`flexi-container`}>
        <div className={`heading fb-40`}>
          <p className={`font-heading-2`}>Designing the User Interface</p>
        </div>
        <div className={`body fb-60`}>
          <p className={`body-heading-0 font-heading-3 tt-uc0 `}>
            Introducing Home
          </p>
          <p className={``}>
            I designed an entry point which the user shall always land by
            default. The Home will itself give an overview of what this
            application has to offer.
          </p>
        </div>
      </div>
      {/* section-divider */}
      <div className={`flexi-container`}>
        <div className={`heading fb-40`}>
          <p className={`font-heading-2`}>Visual Language</p>
        </div>
        <div className={`body fb-60`}>
          <p className={``}></p>
        </div>
      </div>
      {/* section-divider */}
      <div className={`flexi-container`}>
        <div className={`heading fb-40`}>
          <p className={`font-heading-2`}>Final Design</p>
        </div>
        <div className={`body fb-60`}>
          <p className={`body-heading-0 font-heading-3 tt-uc0 `}>
            Welcome to PayNRemit
          </p>
          <p className={``}>
            Greetings! Start a new payment or continue where you left. You can
            come here whenever you want to explore other options and your
            payment progress will be saved automatically.
          </p>
          <p className={` body-img w-45`}>
            <img
              alt="image"
              src={process.env.PUBLIC_URL + "/assets/works/p-pnr/welcome.webp"}
            />
          </p>
          <p className={`body-heading-0 font-heading-3 tt-uc0 `}>
            More organised and segmented
          </p>
          <p className={``}>
            The number of invoices to remit can be a large. Instead of doing
            everything on a single screen, the user has all the invoices
            properly segmented. Here, reducing cognitive load is more important
            than reducing user efforts in terms of taps or clicks.
          </p>
          <p className={`body-img w-45`}>
            <img
              alt="image"
              src={
                process.env.PUBLIC_URL +
                "/assets/works/p-pnr/select_invoices.webp"
              }
            />
          </p>
          <p className={`body-heading-0 font-heading-3 tt-uc0 `}>
            Intuitive Invoices
          </p>
          <p className={``}>
            After selecting customers and their respective invoices to remit,
            come to step three where you — enter remittance amount, swipe,
            repeat until all the invoices selected for remittance are covered.
          </p>
          <p className={` body-img w-45 `}>
            <img
              alt="image"
              src={
                process.env.PUBLIC_URL + "/assets/works/p-pnr/edit_remit.webp"
              }
            />
          </p>
          <p className={`body-heading-0 font-heading-3 tt-uc0 `}>
            Different paths from Home
          </p>
          <p className={``}>
            Remittance Summary after payment collection helps you sync the
            payment information to the cloud. Payment History has an audit of
            the remittances made. User Preferences allows some level of
            personification to the user.
          </p>
        </div>
      </div>
      <div className={``}>
        <img
          alt="image"
          src={
            process.env.PUBLIC_URL + "/assets/works/p-pnr/other_options.webp"
          }
        />
      </div>
      <div className={``}>
        <img
          alt="image"
          src={process.env.PUBLIC_URL + "/assets/works/p-pnr/all.webp"}
        />
      </div>
      {/* section-divider */}
      <div className={`flexi-container`}>
        <div className={`heading fb-40`}>
          <p className={`font-heading-2`}>Next Steps</p>
        </div>
        <div className={`body fb-60`}>
          {/* <p className={``}>
  Speaking loud, I still have ideas gushing in my mind whenever I have to endure a poor expererience. My biggest learning from this exercise has been the belief that though it is important and fruitful to brainstorm ideas but every idea has to be thought through  every small design assignment that needs an experience enhancment
      </p> */}
          <p className={``}>
            If given the opportunity, I would like to completely redesign the
            whole application from scratch as if I am given a blank canvas to
            paint on so that I can leverage the potential of what and how a B2B
            merchant/supplier application can offer that can remarkably change
            the daily lives of field sales representatives.
          </p>
        </div>
      </div>
      {/* section-divider */}
    </div>
  );
}

export default WorkD;
