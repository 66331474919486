import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  Routes,
  Outlet,
  useParams,
  useNavigate,
  Navigate,
  Route,
} from "react-router-dom";
import {
  motion,
  useSpring,
  useTransform,
  AnimatePresence,
} from "framer-motion/dist/framer-motion";
//utils
import THEMES from "../utils/themes";
import TRANSITIONS from "../utils/transitions";
import VARIANTS from "../utils/variants";
//local styles
import styles from "./Versions.module.css";

//using locomotive scroll causing an issue on fixed overlay modals.

function Versions({ version, showVersions }) {
  const variants = VARIANTS;
  const transitions = TRANSITIONS;
  const navigate = useNavigate();
  const themes = THEMES;
  const location = useLocation();
  return (
    <motion.div
      className={`${styles.container} w-90 h-90 flex flex-col flex-ai-fs flex-jc-sb gap padding`}
      key={version}
      variants={variants.focus}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className={`${styles.header} flex  flex-jc-sb flex-ai-fs gap`}>
        <div className={`${styles.headerText} fb-80  `}>
          <div className={`${styles.title} font-heading-2`}>Themes</div>

          <div className={`${styles.subheader} font-body-small opac-hi`}></div>
        </div>

        <div
          className={`${styles.close} font-caption-bold link`}
          onClick={() => showVersions(0)}
        >
          Close
        </div>
      </div>
      <div
        className={`${styles.body} flex flex-col flex-jc-sb flex-ai-fs h-100p `}
      >
        <div
          className={`${styles.themeContainer}  flex flex-col flex-ai-fs gap w-100p`}
        >
          {themes
            .sort((a, b) => a.id - b.id)
            .map((item) => (
              <div
                className={`${styles.themeItem}  w-100p flex flex-ai-fs flex-jc-fs gap`}
              >
                <div className={`${styles.itemThumbnail}  fb-30 w-100p `}>
                  <img className={` pos-a t l w-100p`} src={item.imgUrl} />
                </div>

                <div
                  className={`${styles.itemTitle}  fb-15 flex flex-jc-sb flex-ai-fs `}
                >
                  <div className={`font-heading-3`}>{item.title}&nbsp;</div>

                  {item.default ? (
                    <div className={`${styles.default} opac-hi font-caption`}>
                      Primary
                    </div>
                  ) : null}
                </div>

                <div className={`${styles.itemUrl}  opac-hi fb-15`}>
                  {item.url}
                </div>
                <div className={`${styles.itemTags}  fb-25`}>
                  <div className={`w-90p`}>{item.tags}</div>
                </div>
                <a
                  href={"https://" + item.url}
                  target="_blank"
                  className="link font-caption fb-15 ta-r"
                >
                  <span>[ Switch Theme ]</span>
                </a>
              </div>
            ))}
        </div>
        <div className={`${styles.note} opac-mid font-body-small ta-c `}>
          For better experience, you'll soon be able to switch themes without
          refreshing your browser. Stay tuned.
        </div>
      </div>
    </motion.div>
  );
}

export default Versions;
