import React, { useState, useEffect, useRef } from "react";

//local styles
import styles from "./Scroller.module.css";
export default function Scroller() {
  const [scrolled, setScrolled] = useState(0);
  const [scroller, showScroller] = useState(false);

  useEffect(() => {
    let timeout;

    function handleScrollShow() {
      showScroller(true);

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        showScroller(false);
      }, 400); // Adjust the delay (in milliseconds) as per your preference
    }

    window.addEventListener("scroll", handleScrollShow);
    return () => {
      window.removeEventListener("scroll", handleScrollShow);
      clearTimeout(timeout);
    };
  }, []);
  useEffect(() => {
    function handleScroll() {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      setScrolled(winScroll / height);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scroll]);

  return (
    <div className={`${styles.container} w-100  `}>
      {!(window.innerWidth <= 600) && (
        <div
          className={`${styles.number} font-caption pos-a t r padding `}
          style={{
            transform:
              "translateY(calc((100% * (" +
              scrolled +
              " - 1)) - var(--header-height)))",
          }}
        >
          <div
            className={``}
            style={
              scroller
                ? {
                    opacity: "var(--opac-hi)",
                    transform: "translateX(00%)",
                  }
                : {
                    opacity: 0,
                    transform: "translateX(100%)",
                    transitionDelay: "300ms",
                  }
            }
          >
            {Math.round(scrolled * 100)}
          </div>
        </div>
      )}
      <div
        className={`${styles.indicator} o-h pos-a b l r`}
        style={
          scroller
            ? {
                opacity: 1,
                transform: "translateY(0)",
              }
            : {
                opacity: 0,
                transform: "translateY(-100%)",
              }
        }
      >
        <div
          className={`${styles.content}`}
          style={{
            transform:
              "translateY(-50%) translateX(calc(100% * (" +
              scrolled +
              " - 1)))",
          }}
        />
      </div>
    </div>
  );
}
