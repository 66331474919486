import React, { useState, useEffect, useRef } from "react";
import FontFaceObserver from "fontfaceobserver";

import {
  NavLink,
  useLocation,
  Routes,
  Outlet,
  useParams,
  useNavigate,
  Navigate,
  Route,
  useHref,
} from "react-router-dom";
import {
  motion,
  useSpring,
  useTransform,
  AnimateSharedLayout,
  AnimatePresence,
  useScroll,
} from "framer-motion/dist/framer-motion";

//components
import Root from "./components/Root";
import Preloader from "./components/Preloader";
import Spinner from "./components/Spinner";
import Cursor from "./components/Cursor";
import Scroller from "./components/Scroller";

//utils
// import { keepTheme } from "./utils/theme";
import { useOnLoadImages } from "./hooks/useOnLoadImages";
import VARIANTS from "./utils/variants";

//local styles
import styles from "./App.module.css";

function App() {
  const [className, setClassName] = useState("theme-dark");
  const navigate = useNavigate();
  const variants = VARIANTS;
  const rootRef = useRef(null);
  const [fakeLoaded, setfakeLoaded] = useState(false);
  const [fontLoaded, setFontLoaded] = useState(false);
  const [preloaded, setPreloaded] = useState(false);
  const [windowLoaded, hasWindowLoaded] = useState(false);

  const [gallery, hideGallery] = useState(1);
  const [vanished, setVanished] = useState(0);
  //theme loader
  // useEffect(() => {
  //   keepTheme(setClassName);
  // }, [setClassName]);

  // =======ALL LOADING CHECKS=======//
  //proxy load
  useEffect(() => {
    const timerId = setTimeout(() => {
      setfakeLoaded(true);
    }, 2200);

    return () => clearTimeout(timerId);
  }, []);

  //fonts load
  useEffect(() => {
    const font = new FontFaceObserver("custom-reg");
    const loadFont = async () => {
      try {
        await font.load(null, 5000);
        setFontLoaded(true);
      } catch (error) {
        console.error("Font loading failed:", error);
      }
    };

    loadFont();

    return () => {};
  }, []);

  //images load
  const imagesLoaded = useOnLoadImages(rootRef);
  //console.log(imagesLoaded);

  //window load
  useEffect(() => {
    const handleWindowLoad = () => {
      hasWindowLoaded(true);
    };

    window.addEventListener("load", handleWindowLoad);

    return () => {
      window.removeEventListener("load", handleWindowLoad);
    };
  }, [window]);

  //combined preloaded
  useEffect(() => {
    const showPreloaderTimer = setTimeout(() => {
      if (
        imagesLoaded.status &&
        fakeLoaded &&
        fontLoaded &&
        imagesLoaded.percentage === 100 &&
        windowLoaded
      ) {
        setPreloaded(true);
      }
    }, 800);
    return () => {
      clearTimeout(showPreloaderTimer);
    };
  }, [imagesLoaded.status, fakeLoaded, fontLoaded, windowLoaded]);

  // console.log(
  //   "fakeLoaded x imagesLoaded(%) x fontLoaded x windowLoaded = preloaded \n",
  //   fakeLoaded,
  //   imagesLoaded.status + "(" + imagesLoaded.percentage + ")",
  //   fontLoaded,
  //   windowLoaded,
  //   preloaded
  // );

  //=============== LOAD CHECKS END ==================

  useEffect(() => {
    setVanished(!(window.location.pathname === "/"));
  }, [window.location.pathname]);

  //scroll off while preload
  useEffect(() => {
    !preloaded
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [preloaded]);
  //detect touch
  const isTouch = () =>
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    const handleScroll = () =>
      hideGallery(window.scrollY <= 0 * window.innerHeight);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const anchorDivs = document.querySelectorAll(".anchor-div");
      anchorDivs.forEach((anchorDiv) => {
        const { top, bottom } = anchorDiv.getBoundingClientRect();
        if (top > window.innerHeight || bottom < 0) {
          window.history.replaceState(null, "", window.location.pathname);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //MODALS
  const [menu, showMenu] = useState(0);
  const [version, showVersions] = useState(0);

  useEffect(() => {
    version || menu
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [menu, version]);

  return (
    <>
      <motion.div
        className={`${styles.spinnerContainer}  w-100 flex pos-f t l h-60`}
        variants={variants.appear}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <div
          className={`${styles.spinner} w-100 flex pos-f t l  h-60`}
          style={
            vanished
              ? {
                  opacity: 0,
                  transform: "scale(3)",
                  transitionDelay: "300ms",
                }
              : gallery
              ? {
                  opacity: 1,
                  transform: "scale(1)",
                }
              : {
                  opacity: 0,
                  transform: "scale(3)",
                  transitionDelay: "300ms",
                }
          }
        >
          <Spinner />
        </div>
      </motion.div>
      {!preloaded && (
        <AnimatePresence exitBeforeEnter>
          <motion.div
            className={`${styles.preloaded} pos-f w-100 h-100  t l `}
            key={preloaded}
            variants={variants.fade}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <Preloader percentage={imagesLoaded.percentage} />
          </motion.div>
        </AnimatePresence>
      )}

      <motion.div
        className={`${styles.root}`}
        ref={rootRef}
        key={preloaded}
        style={
          preloaded
            ? {
                opacity: 1,
              }
            : { opacity: 0 }
        }
      >
        {(!isTouch() || window.innerWidth >= 900) && (
          <div className={`${styles.cursor}`}>
            <Cursor menu={menu} version={version} />
          </div>
        )}
        <motion.div
          className={`${styles.scroller}`}
          variants={variants.rise}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <Scroller />
        </motion.div>

        <Root
          gallery={gallery}
          menu={menu}
          showMenu={showMenu}
          version={version}
          showVersions={showVersions}
        />
      </motion.div>
    </>
  );
}

export default App;
