import React, { useState, useEffect } from "react";
//local styles
import styles from "./ThemeToggle.module.css";

const ThemeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const updateFavicon = (theme) => {
    const favicon = document.getElementById("favicon");
    const faviconPath =
      theme === "dark" ? "/favicon-dark.ico" : "/favicon-light.ico";
    favicon.href = process.env.PUBLIC_URL + faviconPath;
  };

  useEffect(() => {
    const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)");
    const hasDarkModePreference = prefersDarkMode.matches;

    const storedTheme = localStorage.getItem("theme");
    const hasStoredTheme = storedTheme === "dark" || storedTheme === "light";

    if (hasStoredTheme) {
      setIsDarkMode(storedTheme === "dark");
    } else if (hasDarkModePreference) {
      setIsDarkMode(true);
    }

    const handleSystemThemeChange = (event) => {
      setIsDarkMode(event.matches);
    };

    prefersDarkMode.addEventListener("change", handleSystemThemeChange);

    return () => {
      prefersDarkMode.removeEventListener("change", handleSystemThemeChange);
    };
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute(
      "data-theme",
      isDarkMode ? "dark" : "light"
    );
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");
    updateFavicon(isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <span className={`${styles.container}`}>
      <span className={`${styles.bn}`} onClick={toggleTheme}>
        {isDarkMode ? <>White</> : <>Black</>}
      </span>
    </span>
  );
};

export default ThemeToggle;
