import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Link, Switch, useNavigate } from "react-router-dom";
import { Route } from "react-router";

//components
import ThemeToggle from "../../ThemeToggle";
//utils

import TRANSITIONS from "../../../utils/transitions";
import VARIANTS from "../../../utils/variants";
import TOOLS from "../../../utils/tools";

//local styles
import styles from "./Toolkit.module.css";

function Toolkit({ linkEnter, linkLeave, extLinkEnter, extLinkLeave }) {
  const navigate = useNavigate();
  const variants = VARIANTS;
  const tools = TOOLS;
  const categoryArr = (rawArr) =>
    rawArr
      .map((item) => item.category)
      .filter(
        (i, index) => rawArr.map((item) => item.category).indexOf(i) === index
      );
  return (
    <div className={`${styles.container} drilldown-page  `}>
      <div className={`${styles.wrapper}  `}>
        {categoryArr(tools).map((setId) => (
          <div key={setId} className={`${styles.categorySet} `}>
            <div className={`${styles.setHeader}  font-caption-bold opac-hi `}>
              {setId}
            </div>
            <div className={`${styles.toolList}`}>
              {tools
                .filter((item) => item.category === setId)
                .map((item) => (
                  <div key={item.id} className={`${styles.setItem}  `}>
                    <div
                      className={`${styles.toolItem}  flex flex-ai-fs flex-jc-fs gap`}
                    >
                      <img
                        alt="image"
                        className={`${styles.itemLogo} `}
                        src={item.logoUrl}
                      />

                      <div
                        className={`${styles.itemText}  flex flex-col flex-ai-fs flex jc-fs  `}
                      >
                        <a
                          href={item.link}
                          target="_blank"
                          className={`${styles.itemAnchor} link flex flex-jc-fs flex-ai-fs `}
                        >
                          <div className={`font-heading-3`}>{item.name}</div>
                          <div className={`font-heading-small opac-hi `}>
                            &nbsp;&nbsp;↗
                          </div>
                        </a>

                        <div className={`font-body-small opac-mid `}>
                          {item.description}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Toolkit;
