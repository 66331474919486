import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Link, Switch, useNavigate } from "react-router-dom";
import { Route } from "react-router";

//components
import ThemeToggle from "../../ThemeToggle";
//utils

import TRANSITIONS from "../../../utils/transitions";
import VARIANTS from "../../../utils/variants";
import LINKS from "../../../utils/links";

//local styles
import styles from "./Links.module.css";

function Links({ linkEnter, linkLeave, extLinkEnter, extLinkLeave }) {
  const navigate = useNavigate();
  const variants = VARIANTS;
  const links = LINKS;
  const categoryArr = (rawArr) =>
    rawArr
      .map((item) => item.category)
      .filter(
        (i, index) => rawArr.map((item) => item.category).indexOf(i) === index
      );
  return (
    <div className={`${styles.container} drilldown-page `}>
      <div className={`${styles.wrapper}`}>
        {categoryArr(links).map((setId) => (
          <div key={setId} className={`${styles.categorySet} `}>
            <div className={`${styles.setHeader}  font-caption-bold opac-hi `}>
              {setId}
            </div>
            <div className={`${styles.setItem}  `}>
              {links
                .sort((a, b) => a.id - b.id)
                .filter((item) => item.category === setId)
                .map((item) => (
                  <div className={`${styles.linkItem}  `}>
                    <div
                      className={`${styles.itemMain} flex flex-jc-sb flex-ai-fs`}
                    >
                      <a
                        href={item.link}
                        target="_blank"
                        className={`${styles.itemContent}  link  flex flex-jc-sb flex-ai-fs gap`}
                      >
                        <img
                          alt="image"
                          className={`${styles.itemLogo} o-h `}
                          src={item.logoUrl}
                        />
                        <div
                          className={`${styles.itemText} fb-auto  flex flex-jc-sb flex-ai-fs`}
                        >
                          <div
                            className={`${styles.itemAnchor} fb-50 flex flex-jc-fs flex-ai-fs `}
                          >
                            <div className={`tt-lc font-body-heading`}>
                              {item.name}
                            </div>
                            <div className={`oapc-hi`}>@samarthjajodia</div>
                          </div>
                          {window.innerWidth < 600 ? (
                            <div
                              className={`${styles.itemDesc} opac-hi fb-50 font-body-small`}
                            >
                              {item.description}
                            </div>
                          ) : (
                            <div
                              className={`${styles.itemDesc} opac-hi fb-50 `}
                            >
                              {item.description}
                            </div>
                          )}
                        </div>
                        <div
                          className={`${styles.itemArrow} font-body-heading opac-hi ta-r `}
                        >
                          ↗
                        </div>
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Links;
