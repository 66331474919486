import React from "react";
import styles from "./WorkL.module.css";
import LoaderHOC from "../../../hocs/LoaderHOC";

function WorkL(props) {
  return (
    <div className={`${styles.container} drilldown-page`}>
      <div className={`heading font-heading-2`}>Context</div>
      <div className={` `}>
        In world of automation evolving rapidly within the humanity, we at
        Autonomous Receivables decided to speed up the daily working hours of
        A/R analysts, and AI-enabled conversational voice assistants could play
        a major role in achieving this in the most convenient and effective way
        possible.
      </div>

      <div className={`heading font-heading-2`}>Problem Area</div>
      <div className={` `}>
        The existing workflow for A/R analysts involved performing tasks that
        are quite simple to perform yet very important to act upon as an action
        item, often leading to time-consuming and distracted workflows. There
        was a virtual agent acting as an assistant already built into the
        application but we recognized that there needs to be a more intuitive,
        efficient & humane interface to empower analysts in their day-to-day
        activities. Being aware of how automation and conversational
        interactions helps achieve more less time, I wanted to synthesize the
        potential of DialogFlow AI to the maximum and solutionize what we could
        really achieve.
      </div>
      <div className={`heading font-heading-2`}>Project Goal</div>
      <div className={` `}>
        Given the above, we're parallelly working on upgrading the visual
        interface making it more aesthetically usable as a touch- & voice-based
        product. So, I needed to make sure that the new Freeda design aligns
        with newly established design language.
      </div>

      <div className={`heading font-heading-2`}>Solution</div>

      <div className={`image`}>
        <video
          src={process.env.PUBLIC_URL + "/assets/p-hrc-freeda/sol.mp4"}
          className={`${styles.itemMediaContent}`}
          preload="auto"
          autoPlay={1}
          loop={1}
          playsInline={1}
          webkit-playsinline="1"
          muted={1}
        ></video>
      </div>

      <div className={``}>
        <a
          href="https://read.cv/samarthjajodia/OX7iA4hn0GiU0QLHbg9Q"
          target="_blank"
          className={`link font-caption-bold`}
        >
          read more&nbsp;
        </a>
        <span className={`font-caption-bold opac-mid`}>&nbsp;Protected</span>
      </div>
    </div>
  );
}
export default WorkL;
