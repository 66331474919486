import React, {
  lazy,
  Suspense,
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
} from "react";
import { NavLink, Switch, useLocation, useNavigate } from "react-router-dom";

//components
import WithThemePersistence from "./WithThemePersistence";
//utils
import ME from "../utils/me";
//local styles
import styles from "./Header.module.css";

function Header({ menu, showMenu, version, showVersions }) {
  const meItems = ME;
  const [mobile, showMobile] = useState(0);
  const location = useLocation();
  //console.log(location, location.hash);
  useLayoutEffect(() => {
    showMobile(window.innerWidth <= 900);
  }, [mobile]);
  return (
    <div className={`${styles.container} w-100`}>
      {!mobile ? (
        <div
          className={`${styles.links} font-caption-bold  flex flex-jc-sb flex-ai-fs`}
        >
          <div className={`${styles.primary} fb-50`}>
            <NavLink
              to=""
              // className={({ isActive }) => (isActive ? "linkActive" : "link")}
              className="link"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Samarth Jajodia
            </NavLink>
            &nbsp;—&nbsp;
            {meItems.map((item) => (
              <React.Fragment key={item.id}>
                {item.component ? (
                  <NavLink
                    to={item.url}
                    className="link"
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    {item.linkName}
                  </NavLink>
                ) : (
                  <a
                    href={`#${item.url}`}
                    className="link"
                    onClick={() => {
                      window.scrollTo({
                        behavior: "smooth",
                      });
                    }}
                  >
                    {item.linkName}
                  </a>
                )}
                <span>&nbsp;/&nbsp;</span>
              </React.Fragment>
            ))}
          </div>
          <div className={`${styles.social} fb-35 flex flex-jc-fs  flex-ai-ce`}>
            <a
              href="https://www.linkedin.com/in/samarthjajodia"
              target="_blank"
              className="link"
            >
              LinkedIn
            </a>
            &nbsp;/&nbsp;
            <a
              target="_blank"
              href="https://www.bento.me/samarthjajodia"
              className="link"
            >
              My Bento
            </a>
            &nbsp;/&nbsp;
            <a
              target="_blank"
              href="https://www.read.cv/samarthjajodia"
              className="link"
            >
              Read.cv
            </a>
            &nbsp;/&nbsp;
            <a
              target="_blank"
              href="https://www.topmate.io/samarthjajodia"
              className="link"
            >
              Book 1:1 call
            </a>
          </div>
          <div className={`${styles.toggles} fb-15  flex flex-jc-fe`}>
            <div
              className={`${styles.version} link`}
              onClick={() => showVersions(!version)}
            >
              Theme
            </div>
            &nbsp;/&nbsp;
            <div className={`${styles.theme} link`}>
              <WithThemePersistence />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${styles.links} font-caption-bold flex gap flex-jc-sb flex-ai-fs `}
        >
          <div
            className={`${styles.samarthJajodia} link fb-40`}
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            {window.innerWidth > 400 ? (
              <NavLink to="">Samarth Jajodia</NavLink>
            ) : (
              <NavLink to="">SJ</NavLink>
            )}
          </div>
          <div className={`${styles.menu} link fb-20 ta-c`}>
            <div
              className={`${styles.menuContent}`}
              onClick={() => showMenu(!menu)}
            >
              Menu
            </div>
          </div>

          <div className={`${styles.themeToggle} link fb-40  ta-r`}>
            <div className={`${styles.theme}`}>
              <WithThemePersistence />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
