import React from "react";
import styles from "./WorkI.module.css";
import LoaderHOC from "../../../hocs/LoaderHOC";

function WorkI(props) {
  return (
    <div className={`${styles.container} drilldown-page`}>
      <div className={`heading font-heading-2`}>The pioneer project</div>
      <div className={` `}>
        It all started with my manager inviting me ad hoc to a call with the
        product strategists, where the latter explained to me Record to Report
        process and the vision behind building a web-based cloud application
        that automates this process and everything around it. I needed to come
        up with something better and new by leveraging the tech capabilities,
        also focusing on third-party in-app integrations (eg. banks, ERPs) as
        well in order to deliver a seamless R2R experience in order for users to
        automate their financial operations using the app. As this product was
        going to be engineered upon a completely differently architecture and
        platform so with enough creative liberty, I was asked to present few
        drafts guided by user understanding and desk research about the CFO tech
        domain.
      </div>
      <div className={``}>
        <a
          href="https://www.highradius.com/software/autonomous-accounting/"
          target="_blank"
          className={`link font-caption-bold `}
        >
          Website ↗
        </a>
      </div>
      <div className={`font-caption`}>
        Products showcase
        <br />
        <br />
        <a
          href="https://youtu.be/7c5HlqSHaYs"
          target="_blank"
          className={`link font-caption-bold `}
        >
          Financial Close ↗
        </a>
        <br />
        <br />
        <a
          href="https://www.highradius.com/software/autonomous-accounting/account-reconciliation/"
          target="_blank"
          className={`link font-caption-bold `}
        >
          Account Reconciliation ↗
        </a>
        <br />
        <br />
        <a
          href="https://www.highradius.com/software/autonomous-accounting/anomaly-management/"
          target="_blank"
          className={`link font-caption-bold `}
        >
          Anomaly Management ↗
        </a>
        <br />
        <br />
        <a
          href="https://www.highradius.com/livecube/"
          target="_blank"
          className={`link font-caption-bold `}
        >
          LiveCube (record to report) ↗
        </a>
      </div>
      <div className={`font-body-small opac-hi`}>
        Due to company's privacy policy, I cannot share every detail of my work
        so following illusrations will contain modified elements retaining the
        essence of showcasing the project.
      </div>
      <div className={`heading font-heading-2`}>Process</div>
      <div className={``}>
        After that I was on my own, exploring and understanding the problem
        space and domain within which I was aiming to craft the product & its
        UX. Once the limitations...
      </div>

      <div className={`heading font-body-heading`}>.&nbsp;.&nbsp;.&nbsp;</div>
      <div className={``}>
        <a
          href="https://read.cv/samarthjajodia/J2E63OAbVNFd7f8A8qGH"
          target="_blank"
          className={`link font-caption-bold`}
        >
          read more&nbsp;
        </a>
        <span className={`font-caption-bold opac-mid`}>&nbsp;Protected</span>
      </div>
    </div>
  );
}
export default WorkI;
