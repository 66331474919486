import React from "react";
import styles from "./WorkM.module.css";
import LoaderHOC from "../../../hocs/LoaderHOC";

function WorkM(props) {
  return (
    <div className={`${styles.container} drilldown-page`}>
      <div className={`heading font-heading-2`}>About</div>
      <div className={` `}>
        HighRadius, a prominent B2B SaaS company, offers a diverse range of
        products tailored for businesses worldwide. Their product portfolio
        encompasses solutions for various stages of growth, including enterprise
        cloud software designed to assist large organizations. Over the course
        of a year, I had the opportunity to contribute to one of their key
        offerings called Autonomous Receivables.
      </div>
      <div className={``}>
        Autonomous Receivables is an AI-powered web application specifically
        developed to support Account Receivables departments of HighRadius'
        customers.
      </div>
      <div className={`font-body-small opac-hi`}>
        To maintain confidentiality, certain aspects of my work, such as
        designs, numbers, and text, have intentionally been modified, blurred,
        or omitted. The specific details and objects of my work cannot be
        disclosed due to company policies.
      </div>
      <div className={`heading font-heading-2`}>My role</div>
      <div className={` `}>
        As a member of their team for over a year, my focus was on Autonomous
        Receivables, a touch and voice web application. Throughout this period,
        my responsibilities included:
      </div>
      <div className={``}>
        <ul>
          <li>15+ features and end-to-end flows,</li>
          <li>
            Establishing new design guidelines, refining the visual language of
            the product,
          </li>
          <li>Designing UX for environment associated to the core feature,</li>
          <li>
            Crafting empty state patterns due to lack of data, data fetching or
            rendering issues.
          </li>
          <li>Scaling the application to handle complex scenarios, and</li>
          <li>
            Contributing to its growth, which saw it reaching over a larger user
            base.
          </li>
        </ul>
      </div>
      <div className={` `}>
        For certain UI patterns we're inspired by my designs from the previous
        works done for other HighRadius products, while we also took a few
        inspirations from other successful products in the market with a large
        user base. To showcase my contributions, I have curated a collection of
        interface designs that exemplify the breadth and depth of my work on
        this platform.
      </div>
      <div className={` `}>
        It is important to note that all the presented designs were either
        created by me individually or in collaboration with other seniors
        designers or peers.
      </div>
      <div className={`heading font-heading-2`}>The Analyst</div>
      <div className={` `}>
        For A/R Analysts, to make their life easy, we had to come up with
        something that can quickly help them assess their own performance...
      </div>
      <div className={``}>
        <a
          href="https://read.cv/samarthjajodia/NBQYqrVmSYsY4asf9qFu"
          target="_blank"
          className={`link font-caption-bold`}
        >
          read more&nbsp;
        </a>
        <span className={`font-caption-bold opac-mid`}>&nbsp;Protected</span>
      </div>
    </div>
  );
}
export default WorkM;
