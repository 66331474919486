const TOOLS = [
  {
    id: 0,
    category: "Design & Art",
    name: "Figma",
    description:
      "For digital design, 3D, art, animations, almost anything & everything",
    logoUrl: "https://cdn.cdnlogo.com/logos/f/43/figma.svg",
    link: "https://www.figma.com",
  },
  {
    id: 1,
    category: "Design & Art",
    name: "Blender",
    description: "How can a 3D scene renderer be so easy to use?",
    logoUrl: "https://cdn.cdnlogo.com/logos/b/32/blender.svg",
    link: "https://www.blender.org/",
  },
  {
    id: 2,
    category: "Design & Art",
    name: "Photoshop",
    description: "My first digital design tool and I guess yours too",
    logoUrl: "https://cdn.cdnlogo.com/logos/a/8/adobe-photoshop-cc.svg",
    link: "https://www.adobe.com/in/products/photoshop.html",
  },
  {
    id: 3,
    category: "Design & Art",
    name: "Adobe XD",
    description: "Here I kickstarted my UX journey. I've also made videos here",
    logoUrl: "https://cdn.cdnlogo.com/logos/a/95/adobe-xd.svg",
    link: "https://helpx.adobe.com/support/xd.html",
  },
  {
    id: 4,
    category: "Development",
    name: "VS Code",
    description: "I love how light-weight, synced and integrated it is",
    logoUrl: "https://cdn.cdnlogo.com/logos/v/82/visual-studio-code.svg",
    link: "https://code.visualstudio.com/",
  },
  {
    id: 5,
    category: "Design & Art",
    name: "After Effects",
    description: "The last resort for my motion design and animation goals",
    logoUrl:
      "https://www.adobe.com/content/dam/cc/us/en/products/ccoverview/ae_cc_app_RGB.svg",
    link: "https://www.adobe.com/in/products/aftereffects.html",
  },
  {
    id: 6,
    category: "Development",
    name: "React JS",
    description: "I have some kind of affinity towards this artsy framework",
    logoUrl: "https://cdn.cdnlogo.com/logos/r/63/react.svg",
    link: "https://react.dev",
  },
  {
    id: 7,
    category: "Design & Art",
    name: "Spline",
    description: "3D made easy here. I wish to integrate a model in React",
    logoUrl:
      "https://spline.design/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fspline_logo.647803e0.png&w=128&q=75",
    link: "https://spline.design",
  },
  {
    id: 8,
    category: "Development",
    name: "Framer Motion",
    description: "Awesome for quick exit animations",
    logoUrl: "https://www.vectorlogo.zone/logos/framer/framer-icon.svg",
    link: "https://framer.com",
  },
  {
    id: 9,
    category: "Development",
    name: "GSAP",
    description: "Helps me build exceptional human interactive animations",
    logoUrl: "https://cdn.cdnlogo.com/logos/g/31/gsap-greensock.svg",
    link: "https://greensock.com/",
  },
  {
    id: 10,
    category: "Showcase",
    name: "Medium",
    description: "Find my UX case studies and design documentations here",
    logoUrl: "https://cdn.cdnlogo.com/logos/m/21/monogram-medium.svg",
    link: "https://medium.com/",
  },
  {
    id: 11,
    category: "Showcase",
    name: "Bento",
    description: "I showcase my artsy crafts here that recently got featured",
    logoUrl:
      "https://global-uploads.webflow.com/6335b33630f88833a92915fc/63860225045ce50e33d20eb3_Logo.svg",
    link: "https://bento.me/",
  },
  {
    id: 12,
    category: "Showcase",
    name: "Notion",
    description: "I love it's potential to deliver a seamless documenting UX",
    logoUrl: "https://cdn.cdnlogo.com/logos/n/55/notion-1.svg",
    link: "https://notion.so/",
  },

  {
    id: 13,
    category: "Showcase",
    name: "Read.cv",
    description: "I'm fascinated by what we can achieve with this tool",
    logoUrl:
      "https://uploads-ssl.webflow.com/62016deaf2189207a9b1784c/627012ff5aca2e86fb271203_read%20cv.svg",
    link: "https://read.cv/explore",
  },
];
export default TOOLS;
