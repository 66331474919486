import React from "react";
import styles from "./WorkG.module.css";
import LoaderHOC from "../../../hocs/LoaderHOC";

function WorkG() {
  return (
    <div className={`${styles.container} drilldown-page`}>
      <div className={``}>
        <a
          href="https://bootcamp.uxdesign.cc/discord-ux-enhancement-156ac9c89d76"
          target="_blank"
          className="link"
        >
          Link to Case Study ↗
        </a>
      </div>
    </div>
  );
}
export default WorkG;
