import React from "react";
import styles from "./WorkF.module.css";
import LoaderHOC from "../../../hocs/LoaderHOC";
function WorkF(props) {
  return (
    <div className={`${styles.container} drilldown-page`}>
      <div className={`heading font-heading-2`}>Context</div>
      <div className={` `}>
        During my time as an intern at Highradius, I have been majorly involved
        in establishing an updated design language for Highradius products. As
        we progressed with the new style guides and components, our team was
        parellely incorporating the new standardized design language across all
        products and Highradius PayNRemit was one of them.
      </div>
      <div className={``}>
        Though, apart from updating the visuals, there were chunks of UX
        problems to solve as well that could enhance the user experience.
      </div>
      <div className={`heading font-heading-2`}>Understanding the business</div>
      <div className={` `}>
        Prior to discussing the app and its problems, it was crucial to
        understand the business and how the mobile application generates
        revenue. Therefore, I obtained knowledge about the business and how the
        app facilitates its services to design effective solutions for any
        issues related to the application by setting up meetings with the
        management.
      </div>
      <div className={`sub-heading font-heading-3`}>
        Overview of a B2B wholesale transaction
      </div>
      <div className={`image`}>
        <img
          src={
            process.env.PUBLIC_URL + "/assets/works/p-pnr/b2b-transaction.webp"
          }
        />
      </div>
      <div className={` `}>
        As mentioned above, there are two methods for completing this
        transaction: payment settlement by the supplier or by the buyer.
      </div>
      <div className={`font-body-ital `}>
        In the current project, our focus is on studying the supplier-end
        remittance settlement process and redesigning the experience for it.
        Although I also designed the buyer flow as well, it was descoped for
        later by management. I will share a few screens from the buyer flow that
        were signed off for development.
      </div>
      <div className={`heading font-heading-2`}>Understanding the Problem</div>
      <div className={``}>
        PM team came with following statement —<br />
      </div>
      <div className={`font-quote`}>
        "Field sales representatives find it difficult to collect remittances
        from their customers. It's a very time-consuming process for them."
      </div>
      <div className={``}>
        Stating the above, they walked me through the entire application
        explaining the entire payment process along which they pointed out
        problems faced by sales agents. With this, they gave me the task to run
        in parellel — visually uplift the application. Then I started using the
        application myself where I could clearly identify some of the root
        causes that were leading to unintended user problems.
      </div>
      <div className={`sub-heading font-heading-3 `}>
        How the app helps supplier-end settlement
      </div>
      <div>
        The HighRadius Supplier App facilitates the remittance collection
        process, which involves several steps. In this process, the supplier's
        accounts receivable representative visits the customer (here, Walmart)
        to collect the remittance and settle any open invoices on behalf of the
        customer.
      </div>
      <div className={`image img-lg`}>
        <img
          src={
            process.env.PUBLIC_URL +
            "/assets/works/p-pnr/supplier-end-process.webp"
          }
        />
      </div>
      <div className={`sub-heading font-heading-3 `}>Challenges</div>
      <div>
        After subsequent discussions with the product management (who had
        customer feedbacks), engineering and other internal stakeholders I made
        a consolidated list of problems, solving of which could effectively
        improve the user experience to significant amount.
      </div>
      <div className={`image`}>
        <img
          src={process.env.PUBLIC_URL + "/assets/works/p-pnr/challenges.webp"}
        />
      </div>
      <div className={`heading font-heading-2`}>Constraints</div>
      <div className={``}>
        <ul></ul>
        <li>
          Users have specific expectations for collecting remittances using the
          mobile app.
        </li>
        <li>
          Improvements to the user experience should not disrupt the existing
          system.
        </li>
        <li>
          Current design language components should be used with minimal
          modifications.
        </li>
      </div>
      <div className={`heading font-heading-2`}>Initial screens</div>
      <div className={``}>Interface prior to the design overhaul:</div>
      <div className={`image`}>
        <img
          src={
            process.env.PUBLIC_URL + "/assets/works/p-pnr/old-supplier-1.webp"
          }
        />
      </div>
      <div className={`image`}>
        <img
          src={
            process.env.PUBLIC_URL + "/assets/works/p-pnr/old-supplier-2.webp"
          }
        />
      </div>
      <div className={`image`}>
        <img
          src={
            process.env.PUBLIC_URL + "/assets/works/p-pnr/old-supplier-3.webp"
          }
        />
      </div>
      <div className={`image`}>
        I realized that aesthetics uplift can greatly enhance usability.
      </div>
      <div className={`heading font-heading-2`}>Design Principles</div>
      <div className={`image`}>
        Based on findings and insights gained till now I laid out some design
        principles that will help me to design accordingly driven by core
        fundamentals.
      </div>
      <div className={`${styles.secGrid}  grid no-padding`}>
        <div className={`${styles.item} sub-heading r-1-c-1-rsp-6-csp-6 `}>
          <div className={`  `}>
            <img
              src={process.env.PUBLIC_URL + "/assets/works/p-pnr/scale.webp"}
            />
          </div>
          <div className={`font-heading-3 sub-heading`}>Design for Scale</div>
          <div className={`sub-heading w-90p`}>
            System should handle increasing information load in both visual and
            functional sense without slowdowns or crashes.
          </div>
        </div>
        <div className={`${styles.item} sub-heading r-1-c-7-rsp-6-csp-6`}>
          <div className={`  `}>
            <img
              src={process.env.PUBLIC_URL + "/assets/works/p-pnr/context.webp"}
            />
          </div>
          <div className={` font-heading-3 sub-heading`}>Provide Context</div>
          <div className={`sub-heading w-90p`}>
            Design should be self-explanatory and show progress to users. They
            must be aware of steps already taken and those yet to take.
          </div>
        </div>
        <div className={`${styles.item} sub-heading r-7-c-7-rsp-6-csp-6`}>
          <div className={``}>
            <img
              src={process.env.PUBLIC_URL + "/assets/works/p-pnr/error.webp"}
            />
          </div>
          <div className={`font-heading-3 sub-heading`}>Error Prevention</div>
          <div className={`sub-heading w-90p`}>
            Design should notify users of potential outcomes of the actions they
            have taken and/or are yet to take. This will help to eliminate
            errors caused by human actions.
          </div>
        </div>
        <div className={`${styles.item} sub-heading r-7-c-1-rsp-6-csp-6`}>
          <div className={``}>
            <img src={process.env.PUBLIC_URL + "/assets/works/p-pnr/ui.webp"} />
          </div>
          <div className={` font-heading-3 sub-heading`}>Visual Harmony</div>
          <div className={`sub-heading w-90p`}>
            UI elements should be consistent and efficiently organized. This
            will allow to make an efficient use of the viewport real-estate.
          </div>
        </div>
      </div>
      <div className={`heading font-heading-2`}>Restructuring Information</div>{" "}
      <div className={` `}>
        Keeping focus on how to fasten the remittance collection process but
        also knowing that the current payment ecosystem can not be modified, I
        decided to optimize the flow by reconnecting the navigation routes by
        placing/adjusting information & actions accordingly to facilitate same.
        This helped me to optimize the overall user flow.
      </div>
      <div className={`sub-heading font-heading-3 `}>
        Information Architecture
      </div>
      <div className={`image img-lg`}>
        <img src={process.env.PUBLIC_URL + "/assets/works/p-pnr/ia.webp"} />
      </div>
      <div className={`sub-heading font-heading-3 `}>User flows</div>
      <div className={`image img-lg`}>
        <img
          src={process.env.PUBLIC_URL + "/assets/works/p-pnr/user-flows.webp"}
        />
      </div>
      <div className={`heading font-heading-2`}>Early explorations</div>
      <div className={`image`}>
        <img
          src={process.env.PUBLIC_URL + "/assets/works/p-pnr/iterations.webp"}
        />
      </div>
      <div className={`heading font-heading-2 `}>Visual Language</div>
      <div className={``}>
        First design language I worked on, where I created new components,
        updated a few and documented all of them in the link below.
      </div>
      <div className={`font-caption-bold link`}>
        <a
          href="https://read.cv/samarthjajodia/vXaAqsBGUMeAkpfzBqR2"
          target="_blank"
          className=""
        >
          Trifecta Design Language ↗
        </a>
      </div>
      <div className={`heading font-heading-2`}>Final Design</div>
      <div className={`sub-heading-0 font-heading-3 sub-heading `}>
        Welcome to PayNRemit
      </div>
      <div className={` `}>
        Greetings! Start a new payment or continue where you left. You can come
        here whenever you want to explore other options and your payment
        progress will be saved automatically.
      </div>
      <div className={` body-img w-45`}>
        <img
          src={process.env.PUBLIC_URL + "/assets/works/p-pnr/welcome.webp"}
        />
      </div>
      <div className={`sub-heading-0 font-heading-3 sub-heading `}>
        More organised and segmented
      </div>
      <div className={` `}>
        The number of invoices to remit can be a large. Instead of doing
        everything on a single screen, the user has all the invoices properly
        segmented. Here, reducing cognitive load is more important than reducing
        user efforts in terms of taps or clicks.
      </div>
      <div className={`image w-45`}>
        <img
          src={
            process.env.PUBLIC_URL + "/assets/works/p-pnr/select_invoices.webp"
          }
        />
      </div>
      <div className={`sub-heading-0 font-heading-3 sub-heading `}>
        Intuitive Invoices
      </div>
      <div className={` `}>
        After selecting customers and their respective invoices to remit, come
        to step three where you — enter remittance amount, swipe, repeat until
        all the invoices selected for remittance are covered.
      </div>
      <div className={` body-img w-45 `}>
        <img
          src={process.env.PUBLIC_URL + "/assets/works/p-pnr/edit_remit.webp"}
        />
      </div>
      <div className={`sub-heading-0 font-heading-3 sub-heading `}>
        Different paths from Home
      </div>
      <div className={` `}>
        Remittance Summary after payment collection helps you sync the payment
        information to the cloud. Payment History has an audit of the
        remittances made. User Preferences allows some level of personification
        to the user.
      </div>
      <div className={`image`}>
        <img
          src={
            process.env.PUBLIC_URL + "/assets/works/p-pnr/other_options.webp"
          }
        />
      </div>
      <div className={`image`}>
        <img src={process.env.PUBLIC_URL + "/assets/works/p-pnr/all.webp"} />
      </div>
      <div className={`heading font-heading-2`}>Closing Notes</div>
      <div className={` `}>
        In my first major undertaking as a design intern, I learnt more about in
        designing solutions within not only time but techical constraints,
        collaborating with developers, communicating and signing-off to the
        product management. Good design is iterative has been my mantra, but a
        successful sign-off requires a well-justified design. While the new
        design captured more users and we could see increasing engagement from
        current ones, I would still love the opportunity to start fresh by
        consulting field sales representatives and merchant owners on a
        ground-level, addressing potential unknown problems. This approach would
        fully leverage the possibilities of a B2B merchant/supplier remittance
        collection process.
      </div>
      <div className={`font-body-small opac-hi`}>
        Thank you for taking the to read through this project.
      </div>
    </div>
  );
}

export default WorkF;
