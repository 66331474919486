import React, { useEffect, useState, useRef } from "react";

import {
  NavLink,
  Link,
  Switch,
  useLocation,
  useNavigate,
} from "react-router-dom";

//components
import ThemeToggle from "../ThemeToggle";
//utils
import TRANSITIONS from "../../utils/transitions";
import VARIANTS from "../../utils/variants";
//local styles
import styles from "./WorkHeader.module.css";

function WorkHeader({ items, item }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [header, showHeader] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      showHeader(window.scrollY < 0.2 * window.innerHeight);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <div className={`${styles.container} flex flex-col flex-jc-sb`}>
      <div
        className={`${styles.controlBar} font-caption-bold flex flex-col  flex-jc-fe flex-ai-fe gap`}
      >
        {/* {items[item.id - 1] ? (
          <div
            className={`${styles.control} link `}
            onClick={() => {
              navigate("/" + items[item.id - 1].url, {
                replace: 1,
              });
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Previous
          </div>
        ) : (
          <div className={`${styles.control} linkDisabled`}>Previous</div>
        )}

      
        {items[item.id + 1] ? (
          <div
            className={`${styles.control} link `}
            onClick={() => {
              navigate("/" + items[item.id + 1].url, {
                replace: 1,
              });
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Next
          </div>
        ) : (
          <div className={`${styles.control} linkDisabled`}>Next</div>
        )} */}
        <br />
        <br />
        {/* close */}
        <div
          className={`${styles.control} link `}
          onClick={() => {
            navigate("../", { replace: 1 });
          }}
        >
          {/* <VscClose /> */}Close
        </div>
      </div>

      <div
        className={`${styles.infoContainer} padding flex  flex-col gap`}
        style={
          !header
            ? {
                opacity: 0,
                filter: "blur(calc(0.5 * var(--spacing)))",
              }
            : {
                opacity: "1",
                filter: "blur(calc(0 * var(--spacing)))",
              }
        }
      >
        <div className={`${styles.infoTitle}  ta-c font-heading-1 `}>
          <div className={`${styles.titleText} `}>{item.title}</div>
        </div>

        <div className={`${styles.infoDesc} ta-c`}>
          <div className={`${styles.descText}  font-body-serif`}>
            {item.description}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkHeader;
